const BtnNav = ({setShowNav}) => {
  // RETURNING
  return (
    <button
      onClick={() => setShowNav(false)}
      className={`
        icon-close 
        text-xl text-white
        hover:text-bold
        focus:outline-none
        inline-block self-end
        ani03s `
      }
    ></button>
  )
}
// Export
export default BtnNav
