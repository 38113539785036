import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
// IMPORT COMPONENTS
import NavItem from './NavItem'
import NavItemLink from './NavItemLink'
import NavSubItem from './NavSubItem'
// COMPONENT
const NavBar = ({ showNav, setShowNav }) => {
    
  // CONSTANTS
  const location = useLocation();
  const mainItem = location.pathname;
  const secItem = location.hash;

  // RETURNING
  return (
    <div className={`
      absolute max-w-640 min-w-460 xs:min-w-0 top-0 xs:w-full right-0 z-10`
    }> 
      <nav className={`
        w-full
        flex flex-col bg-header_top_bg
        justify-center items-start
        py-3 shadow`
      } role="navigation" aria-label="main navigation">
          <NavItem
              title={'REGIONAL IMMUNIZATION SNAPSHOT'}
              navActive={mainItem === '/introduction' ? true : false}>
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Main messages'}
                  linkTo={'/regional-immunization-snapshot-main-messages'}
                  navSubActive={secItem === '/regional-immunization-snapshot-main-messages' ? true : false}
                  hashValue={'/regional-immunization-snapshot-main-messages'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Vaccination coverage and number of infants not vaccinated for DTP1, DTP3 (under-vaccinated), MCV1 and MCV2,2020'}
                  linkTo={'/regional-immunization-snapshot-vaccination-coverage'}
                  navSubActive={secItem === '/regional-immunization-snapshot-vaccination-coverage' ? true : false}
                  hashValue={'/regional-immunization-snapshot-vaccination-coverage'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Vaccination coverage by country, 2020'}
                  linkTo={'/regional-immunization-snapshot-vaccination-coverage-country'}
                  navSubActive={secItem === '/regional-immunization-snapshot-vaccination-coverage' ? true : false}
                  hashValue={'/regional-immunization-snapshot-vaccination-coverage'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Regional vaccination trends, 2000-2020'}
                  linkTo={'/regional-immunization-snapshot-regional-trends'}
                  navSubActive={secItem === '/regional-immunization-snapshot-regional-trends' ? true : false}
                  hashValue={'/regional-immunization-snapshot-regional-trends'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Top 5 countries with the most infants not vaccinated for DTP1(un-vaccinated), not vaccinated for DTP3 (under-vaccinated) and not vaccinated for MCV1 and MCV2, 2020'}
                  linkTo={'/regional-immunization-snapshot-top-5-countries'}
                  navSubActive={secItem === '/regional-immunization-snapshot-top-5-countries' ? true : false}
                  hashValue={'/regional-immunization-snapshot-top-5-countries'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Top 10 countries with the most infants not vaccinated for DTP3, 2018-2020'}
                  linkTo={'/regional-immunization-snapshot-top-10-countries'}
                  navSubActive={secItem === '/regional-immunization-snapshot-top-10-countries' ? true : false}
                  hashValue={'/regional-immunization-snapshot-top-10-countries'}
              />
          </NavItem>
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'LOOKING AT IMMUNIZATION COVERAGE AT SUBNATIONAL LEVEL'}
            navActive={secItem === '/looking-at-immunization-coverage-at-subnational-level' ? true : false}
            linkTo={'/looking-at-immunization-coverage-at-subnational-level'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'IMMUNIZATION IN THE CONTEXT OF COVID-19: STORIES FROM THE FIELD'}
            navActive={secItem === '/immunization-in-the-contect-of-covid-19' ? true : false}
            linkTo={'/immunization-in-the-contect-of-covid-19'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'VACCINE CONFIDENCE, AND INFODEMIC MANAGEMENT'}
            navActive={secItem === '/vaccine-confidence-and-infodemic-management' ? true : false}
            linkTo={'/vaccine-confidence-and-infodemic-management'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'HOW UNICEF EUROPE AND CENTRAL ASIA REGION SUPPORTS IMMUNIZATIONS PROGRAMS'}
            navActive={secItem === '/how-unicef-europe-and-central-asia-regions-supports-immunization-programsprofiles' ? true : false}
            linkTo={'/how-unicef-europe-and-central-asia-regions-supports-immunization-programs'}
          />
          
      </nav> 
  
    </div>
  )
}
// PROPTYPES
NavBar.propTypes = {
  showNav: PropTypes.bool.isRequired,
}
// EXPORT
export default NavBar
