import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table'
import BaseRow from '../../components/BaseRow'
// Component
const TableCountries = () => {

  const [showAlbania, setShowAlbania] = useState(true);
  const handleClickAlbania = () => setShowAlbania(!showAlbania)

  const [showArmenia, setShowArmenia] = useState(true);
  const handleClickArmenia = () => setShowArmenia(!showArmenia)

  const [showAzerbaijan, setShowAzerbaijan] = useState(true);
  const handleClickAzerbaijan = () => setShowAzerbaijan(!showAzerbaijan)

  const [showBelarus, setShowBelarus] = useState(true);
  const handleClickBelarus = () => setShowBelarus(!showBelarus)

  const [showBosnia, setShowBosnia] = useState(true);
  const handleClickBosnia = () => setShowBosnia(!showBosnia)

  const [showBulgaria, setShowBulgaria] = useState(true);
  const handleClickBulgaria = () => setShowBulgaria(!showBulgaria)

  const [showCroatia, setShowCroatia] = useState(true);
  const handleClickCroatia = () => setShowCroatia(!showCroatia)

  const [showGeorgia, setShowGeorgia] = useState(true);
  const handleClickGeorgia = () => setShowGeorgia(!showGeorgia)

  const [showGreece, setShowGreece] = useState(true);
  const handleClickGreece = () => setShowGreece(!showGreece)

  const [showKazakhstan, setShowKazakhstan] = useState(true);
  const handleClickKazakhstan = () => setShowKazakhstan(!showKazakhstan)

  const [showKosovo, setShowKosovo] = useState(true);
  const handleClickKosovo = () => setShowKosovo(!showKosovo)

  const [showKyrgyzstan, setShowKyrgyzstan] = useState(true);
  const handleClickKyrgyzstan = () => setShowKyrgyzstan(!showKyrgyzstan)

  const [showMoldova, setShowMoldova] = useState(true);
  const handleClickMoldova = () => setShowMoldova(!showMoldova)

  const [showMacedonia, setShowMacedonia] = useState(true);
  const handleClickMacedonia = () => setShowMacedonia(!showMacedonia)

  const [showMontenegro, setShowMontenegro] = useState(true);
  const handleClickMontenegro = () => setShowMontenegro(!showMontenegro)

  const [showRomania, setShowRomania] = useState(true);
  const handleClickRomania = () => setShowRomania(!showRomania)

  const [showServia, setShowServia] = useState(true);
  const handleClickServia = () => setShowServia(!showServia)

  const [showTajikistan, setShowTajikistan] = useState(true);
  const handleClickTajikistan = () => setShowTajikistan(!showTajikistan)

  const [showTurkey, setShowTurkey] = useState(true);
  const handleClickTurkey = () => setShowTurkey(!showTurkey)

  const [showTurkmenistan, setShowTurkmenistan] = useState(true);
  const handleClickTurkmenistan = () => setShowTurkmenistan(!showTurkmenistan)

  const [showUkraine, setShowUkraine] = useState(true);
  const handleClickUkraine = () => setShowUkraine(!showUkraine)

  const [showUzbekistan, setShowUzbekistan] = useState(true);
  const handleClickUzbekistan = () => setShowUzbekistan(!showUzbekistan)

  const [showCountries, setShowCountries] = useState(false);

  const data = React.useMemo( () => [
      { 
        country: 'Albania*',
        bcg: "-",
        dtp1: "-",
        dtp3: "99",
        polio3: "-", 
        mcv1: "91", 
        mcv2: "94", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Armenia*',
        bcg: "-",
        dtp1: "-",
        dtp3: "92",
        polio3: "-", 
        mcv1: "94", 
        mcv2: "94", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "8", 
      },
      { 
        country: 'Azerbaijan',
        bcg: "94",
        dtp1: "85",
        dtp3: "79",
        polio3: "85", 
        mcv1: "82", 
        mcv2: "79", 
        hepb3: "79", 
        hib3: "79",  
        rota: "-", 
        pcv3: "79",  
        hpv: "-", 
      },
      { 
        country: 'Belarus',
        bcg: "97",
        dtp1: "97",
        dtp3: "97",
        polio3: "97", 
        mcv1: "97", 
        mcv2: "98", 
        hepb3: "97", 
        hib3: "97",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Bosnia and Herzegovina*',
        bcg: "-",
        dtp1: "-",
        dtp3: "63",
        polio3: "-", 
        mcv1: "52", 
        mcv2: "49", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Bulgaria',
        bcg: "97",
        dtp1: "94",
        dtp3: "91",
        polio3: "91", 
        mcv1: "88", 
        mcv2: "84", 
        hepb3: "91", 
        hib3: "91",  
        rota: "45", 
        pcv3: "85",  
        hpv: "2", 
      },
      { 
        country: 'Croatia',
        bcg: "-",
        dtp1: "-",
        dtp3: "-",
        polio3: "-", 
        mcv1: "-", 
        mcv2: "-", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Georgia',
        bcg: "96",
        dtp1: "95",
        dtp3: "88",
        polio3: "88", 
        mcv1: "91", 
        mcv2: "77", 
        hepb3: "88", 
        hib3: "88",  
        rota: "75", 
        pcv3: "82",  
        hpv: "22", 
      },
      { 
        country: 'Greece*',
        bcg: "-",
        dtp1: "-",
        dtp3: "100",
        polio3: "-", 
        mcv1: "97", 
        mcv2: "83", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Kazakhstan',
        bcg: "93",
        dtp1: "94",
        dtp3: "88",
        polio3: "88", 
        mcv1: "93", 
        mcv2: "91", 
        hepb3: "88", 
        hib3: "88",  
        rota: "52", 
        pcv3: "89",  
        hpv: "-", 
      },
      { 
        country: 'Kosovo*',
        bcg: "-",
        dtp1: "-",
        dtp3: "92",
        polio3: "-", 
        mcv1: "90", 
        mcv2: "93", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Kyrgyzstan',
        bcg: "96",
        dtp1: "90",
        dtp3: "87",
        polio3: "87", 
        mcv1: "92", 
        mcv2: "93", 
        hepb3: "86", 
        hib3: "86",  
        rota: "-", 
        pcv3: "90",  
        hpv: "-", 
      },
      { 
        country: 'Moldova',
        bcg: "95",
        dtp1: "86",
        dtp3: "86",
        polio3: "87", 
        mcv1: "84", 
        mcv2: "93", 
        hepb3: "87", 
        hib3: "86",  
        rota: "56", 
        pcv3: "72",  
        hpv: "40", 
      },
      { 
        country: 'Montenegro',
        bcg: "67",
        dtp1: "94",
        dtp3: "84",
        polio3: "84", 
        mcv1: "24", 
        mcv2: "76", 
        hepb3: "52", 
        hib3: "84",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'North Macedonia',
        bcg: "-",
        dtp1: "-",
        dtp3: "-",
        polio3: "-", 
        mcv1: "-", 
        mcv2: "-", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Romania',
        bcg: "97",
        dtp1: "95",
        dtp3: "87",
        polio3: "87", 
        mcv1: "87", 
        mcv2: "75", 
        hepb3: "87", 
        hib3: "87",  
        rota: "-", 
        pcv3: "85",  
        hpv: "-", 
      },
      { 
        country: 'Serbia*',
        bcg: "-",
        dtp1: "-",
        dtp3: "92",
        polio3: "-", 
        mcv1: "78", 
        mcv2: "84", 
        hepb3: "-", 
        hib3: "-",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Tajikistan',
        bcg: "98",
        dtp1: "98",
        dtp3: "97",
        polio3: "97", 
        mcv1: "98", 
        mcv2: "97", 
        hepb3: "97", 
        hib3: "97",  
        rota: "95", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Turkey',
        bcg: "-96",
        dtp1: "99",
        dtp3: "98",
        polio3: "98", 
        mcv1: "95", 
        mcv2: "93", 
        hepb3: "98", 
        hib3: "98",  
        rota: "-", 
        pcv3: "95",  
        hpv: "-", 
      },
      { 
        country: 'Turkmenistan',
        bcg: "98",
        dtp1: "99",
        dtp3: "98",
        polio3: "98", 
        mcv1: "98", 
        mcv2: "99", 
        hepb3: "99", 
        hib3: "99",  
        rota: "95", 
        pcv3: "23",  
        hpv: "99", 
      },
      { 
        country: 'Ukraine',
        bcg: "93",
        dtp1: "93",
        dtp3: "81",
        polio3: "84", 
        mcv1: "85", 
        mcv2: "82", 
        hepb3: "81", 
        hib3: "85",  
        rota: "-", 
        pcv3: "-",  
        hpv: "-", 
      },
      { 
        country: 'Uzbekistan',
        bcg: "99",
        dtp1: "96",
        dtp3: "95",
        polio3: "97", 
        mcv1: "99", 
        mcv2: "99", 
        hepb3: "95", 
        hib3: "95",  
        rota: "90", 
        pcv3: "93",  
        hpv: "100", 
      },

    ],
    []
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Country', Footer: 'Country', accessor: 'country'},
      { Header: 'BCG', Footer: 'BCG', accessor: 'bcg' },
      { Header: 'DTP1', Footer: 'DTP1', accessor: 'dtp1' },
      { Header: 'DTP3', Footer: 'DTP3', accessor: 'dtp3' },
      { Header: 'Polio3', Footer: 'Polio3', accessor: 'polio3'},
      { Header: 'MCV1', Footer: 'MCV1', accessor: 'mcv1'},
      { Header: 'MCV2', Footer: 'MCV2', accessor: 'mcv2'},
      { Header: 'HepB3', Footer: 'HepB3', accessor: 'hepb3' },
      { Header: 'Hib3', Footer: 'Hib3', accessor: 'hib3'},
      { Header: 'Rota', Footer: 'Rota', accessor: 'rota'},
      { Header: 'PCV3', Footer: 'PCV3', accessor: 'pcv3'},
      { Header: 'HPV', Footer: 'HPV', accessor: 'hpv'},
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable({ columns, data }, useSortBy)

  const checkItems = React.useMemo( () => [
    { 
      country: 'Albania*',
      classItem: 'albania',
      showItem: showAlbania,
      handleItem: handleClickAlbania, 
    },
    { 
      country: 'Armenia*',
      classItem: 'armenia',
      showItem: showArmenia,
      handleItem: handleClickArmenia,
    },
    { 
      country: 'Azerbaijan',
      classItem: 'azerbaijan',
      showItem: showAzerbaijan,
      handleItem: handleClickAzerbaijan,
    },
    { 
      country: 'Belarus',
      classItem: 'belarus',
      showItem: showBelarus,
      handleItem: handleClickBelarus,
    },
    { 
      country: 'Bosnia and Herzegovina*',
      classItem: 'bosnia',
      showItem: showBosnia,
      handleItem: handleClickBosnia,
    },
    { 
      country: 'Bulgaria',
      classItem: 'bulgaria',
      showItem: showBulgaria,
      handleItem: handleClickBulgaria,
    },
    { 
      country: 'Croatia',
      classItem: 'croatia',
      showItem: showCroatia,
      handleItem: handleClickCroatia,
    },
    { 
      country: 'Georgia',
      classItem: 'georgia',
      showItem: showGeorgia,
      handleItem: handleClickGeorgia,
    },
    { 
        country: 'Greece*',
        classItem: 'greece',
        showItem: showGreece,
        handleItem: handleClickGreece,
    },
    {
      country: 'Kazakhstan',
      classItem: 'kazakhstan',
      showItem: showKazakhstan,
      handleItem: handleClickKazakhstan,
    },
    {
        country: 'Kosovo*',
        classItem: 'kosovo',
        showItem: showKosovo,
        handleItem: handleClickKosovo,
    },
    { 
      country: 'Kyrgyzstan',
      classItem: 'Kyrgyzstan',
      showItem: showKyrgyzstan,
      handleItem: handleClickKyrgyzstan,
    },
    {
      country: 'North Macedonia',
      classItem: 'macedonia',
      showItem: showMacedonia,
      handleItem: handleClickMacedonia,
    },
    {
      country: 'Moldova',
      classItem: 'Moldova',
      showItem: showMoldova,
      handleItem: handleClickMoldova,
    },
    {
      country: 'Montenegro',
      classItem: 'montenegro',
      showItem: showMontenegro,
      handleItem: handleClickMontenegro,
    },
    {
      country: 'Romania',
      classItem: 'romania',
      showItem: showRomania,
      handleItem: handleClickRomania,
    },
    {
      country: 'Serbia*',   
      classItem: 'serbia',
      showItem: showServia,
      handleItem: handleClickServia,
    },
    {
      country: 'Tajikistan',
      classItem: 'tajikistan',
      showItem: showTajikistan,
      handleItem: handleClickTajikistan,
    },
    {
      country: 'Turkey',
      classItem: 'turkey',
      showItem: showTurkey,
      handleItem: handleClickTurkey,
    },
    {
      country: 'Turkmenistan',
      classItem: 'turkmenistan',
      showItem: showTurkmenistan,
      handleItem: handleClickTurkmenistan,
    },
    {
      country: 'Ukraine',
      classItem: 'ukraine',
      showItem: showUkraine,
      handleItem: handleClickUkraine,
    },
    {
      country: 'Uzbekistan',
      classItem: 'uzbekistan',
      showItem: showUzbekistan,
      handleItem: handleClickUzbekistan,
    },
  ])

  return (
    <>
      <div className="table-scroll relative min-h-550 mt-10">
        {/* COUNTRIES */}
        <button
          onClick={() => setShowCountries(true)}
          className="
            absolute z-30 top-7 left-2 
            text-white text-xs
            hover:text-sec-blue ani03s"
        >
          Select countries
          <span className="icon-down pl-2"></span>
        </button>
        {/* COUNTRY SELECT LIST */}
        { showCountries &&
          <div className="
            absolute z-40 p-4 m-1 w-52
            flex flex-col
            bg-white border border-main shadow
            text-sm
          ">
            <div className="flex justify-between pb-4 text-xs text-main">
              <div className="">Select to sort</div>
              <button 
                className="icon-close hover:text-sec_blue ani03s"
                onClick={() => setShowCountries(false)}></button>
            </div>
            {checkItems.map(checkItem => (
              <div className="flex items-center justify-between pb-1">
                <div className="pr-2">{ checkItem.country }</div>
                <div className="round_checkbox_box">
                  <div className="center">
                      <input onClick={ checkItem.handleItem } defaultChecked={ checkItem.showItem } type="checkbox" id={ checkItem.classItem }/>
                    <label htmlFor={ checkItem.classItem }></label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        {/* TABLE */}
        <table {...getTableProps()} 
          className="dTable notFixed tableRightBorder tableTextLR
          tableStripes">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="topHeader"
                  >
                    
                    {column.render('Header')}
                    {headerGroup.headers[0].render('Header') === 'Country' ? 
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <span className="sort icon-sort-desc"></span>
                            : <span className="sort icon-sort-asc"></span>
                          : <span className="sort icon-sort"></span>}
                      </span>
                      : ''
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
                if (row.cells[0].value === 'Albania*' && showAlbania) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Armenia*' && showArmenia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Azerbaijan' && showAzerbaijan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Belarus' && showBelarus) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Bosnia and Herzegovina*' && showBosnia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Bulgaria' && showBulgaria) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Croatia' && showCroatia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Georgia' && showGeorgia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Greece*' && showGreece) {
                    return (
                      <BaseRow row={ row } circle = { true } />
                    )
                  } else if (row.cells[0].value === 'Kazakhstan' && showKazakhstan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Kosovo*' && showKosovo) {
                    return (
                      <BaseRow row={ row } circle = { true } />
                    )
                  } else if (row.cells[0].value === 'Kyrgyzstan' && showKyrgyzstan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'North Macedonia' && showMacedonia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Moldova' && showMoldova) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Montenegro' && showMontenegro) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Romania' && showRomania) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Serbia*' && showServia) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Tajikistan' && showTajikistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Turkey' && showTurkey) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Turkmenistan' && showTurkmenistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Ukraine' && showUkraine) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                } else if (row.cells[0].value === 'Uzbekistan' && showUzbekistan) {
                  return (
                    <BaseRow row={ row } circle = { true } />
                  )
                }
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
// Export
export default TableCountries 