import figure1 from '../../images//figure1.png'
import figure2 from '../../images//figure2.png'
import table1 from '../../images//table1.png'
import chart1 from '../../images//chart1.png'
import chart2 from '../../images//chart2.png'
import prototype1 from '../../images//prototype1.png'
import prototype2 from '../../images//prototype2.png'
import ReactTooltip from 'react-tooltip';

let tooltip1 = "<a href='https://www.GAVI.org/sites/default/files/document/programming-guidance---demand-generationpdf.pdf' target='_blank'>https://www.GAVI.org/sites/default/files/document/programming-guidance---demand-generationpdf.pdf</a>";
let tooltip2 = "Ibid"; 
let tooltip3 = "Wilson SL, Wiysonge C Social media and vaccine hesitancy BMJ Global Health 2020;5:e004206"; 
let tooltip4 = "The 16 countries participating in the social listening project are: Albania, Armenia, Bosnia and Herzegovina, France, Georgia, Kazakhstan, Kosovo (UNSCR 1244), Kyrgyzstan, Moldova, Montenegro, North Macedonia, Romania, Serbia, Tajikistan, Uzbekistan."; 
let tooltip5 = "Germani, Federico, Biller-Andorno, Nikola. The anti-vaccination Infodemic on social media: a behavioural analysis. Plos One. March 2021.<a href='https://doi.org/10.1371/journal.pone.0247642' target='_blank'>https://doi.org/10.1371/journal.pone.0247642</a>"; 
let tooltip6 = "Vidgen, Bertie. Who is most vulnerable to health-related misinformation about COVID-19? The Health Foundation. March 2021.</a>"
let tooltip7 = "Stecula, D. A., Kuru, O., & Jamieson, K. H.<a href='https://www.health.org.uk/news-and-comment/blogs/who-is-most-vulnerable-to-health-related-misinformation-about-covid-192020' target='_blank'>(https://www.health.org.uk/news-and-comment/blogs/who-is-most-vulnerable-to-health-related-misinformation-about-covid-192020)</a>.How trust in experts and media use affect acceptance of common anti-vaccination claims. Harvard Kennedy School (HKS) Misinformation Review.<a href='https://doi.org/10.37016/mr-2020-007' target='_blank'>https://doi.org/10.37016/mr-2020-007</a>";

const VaccineConfidence = () => {
    return (
        <div className="pages">
          <article>
            <h2 class="title-section">VACCINE CONFIDENCE, AND INFODEMIC MANAGEMENT</h2>
            <h1 class="title">Strengthening vaccine confidence and infodemic management</h1>
            <div className="col2">
    
                <p>The COVID-19 pandemic is one of the biggest challenges of our generation. It has caused a significant impact on health systems, including on routinevaccination programmes, societies, and on the way we consume information. More than ever, people have been connected to digital channels, including social media, to access and disseminate information, and have been increasingly in contact with mis and disinformation, which have a huge potential to decrease trust in science and vaccines.</p>
                
                <p>Research indicates that there is growing evidence of vaccine delays or refusals due to a lack of trust in the importance, safety, or effectiveness of vaccines, alongside persisting access issues. Therefore, strategies to improve vaccine confidence and uptake should be multi-pronged and go beyond educational campaigns, addressing the public’s concerns, behavioural drivers and barriers for vaccine uptake and include specific tactics to address mis/disinformation. Moreover, to ensure their sustainability, strategies to enhance vaccine demand should be mainstreamed into national policies, programmes, and budgets.</p>

                <p>To address these issues, UNICEF ECARO is currently working on several strategic areas that aim to address complacency, confidence, convenience, and other behavioural factors affecting vaccination demand and confidence in vaccines.</p>
            </div>

            <h6 class="message-title-small mt-20 mb-10">MAINSTREAMING DEMAND FOR IMMUNIZATION IN NATIONAL POLICIES, PROGRAMMES AND BUDGETS</h6>

            <div className="col2">
    
                <p>UNICEF ECARO engaged in 2020 with Oxford Policy Management and Curatio International Foundation to map demand-related interventions, assess their mainstreaming status and formulate country-specific recommendations for better integration of demand for immunization in Armenia, Georgia, Moldova and Kyrgyzstan.</p>

               <p>To map demand for immunization activities, we used GAVI’s definition of demand generation<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip1} data-for="tooltip1">1</sup> , which refers to any intervention, including supply-side interventions that “aims to ensure that parents, caregivers, and communities, value, trust and actively seek immunization”. We mapped the identified demand generation activities by the following components: <sup class="pointer" data-class="custom-tooltip" data-tip={tooltip2} data-for="tooltip2">2</sup></p> 
               <ReactTooltip id="tooltip1" html={true} clickable={true} />
               <ReactTooltip id="tooltip2" html={true} clickable={true} />

            </div>

            <div class="full-w-grey">
                <h6 class="message-title-small font-italic">Figure 1: Demand Generation Intervention Matrix</h6>
                <img src={figure1} alt={`figure1`} />
            </div>
           
            
            <div className="col2">
    
                <p>Mainstreaming the demand generation for immunization essentially means that demand generation interventions are integrated into national and/or sub-national immunization programmes, and/or wider health sector strategies and programmes. This also implies that these activities get priority and optimal budgetary allocations and are adequately resourced and embedded in national or subnational organisational structures. Overall, a fully mainstreamed demand generation model would entail the following features:</p>

            </div>

            <div class="full-w-grey">
                <h6 class="message-title-small font-italic">Figure 2: Features of a fully mainstreamed demand generation model</h6>
                <img src={figure2} alt={`figure2`} />
            </div>

            <div className="col2">
    
                <p>This summary of the mainstreaming status of demand for immunization in Georgia, Kyrgyzstan and Moldova is presented in the table below. Details on assessment approaches, methodology and findings for each country can be found on <a class="custom-link" href="https://unicef.sharepoint.com/:f:/r/teams/ECAR-C4SC-Collab/Repository/Mainstreaming%20demand%20for%20immunization%20(OPM)?csf=1&web=1&e=ULiGrX" target="_blank">UNICEF ECARO Share Point</a>.</p>

            </div>

            <div class="full-w-grey">
                <h6 class="message-title-small font-italic">Table 1: Summary of the mainstreaming status of demand for immunization in Georgia, Kyrgyzstan and Moldova</h6>
                <img className="mt-10" src={table1} alt={`table1`} />
            </div>

            <div className="col2">
    
                <p>In the following period, the assessment phase for Armenia will be finalised, and country-specific recommendations for mainstreaming demand for immunization in national policies, programmes and budgets in all four countries will be formulated.</p>

            </div>

            <h6 class="message-title-small mt-20 mb-10">SOCIAL LISTENING AND INFODEMIC MANAGEMENT</h6>

            <div className="col2">
    
                <p>Research suggests that exposure to vaccine disinformation is associated with higher concerns about vaccine safety and with declines in mean vaccination rates.<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip3} data-for="tooltip3">3</sup> At the same time, research on COVID-19 protective measures also suggests that social media platforms can positively influence awareness of public health behavioural changes and public protection against COVID-19, however, more research is required to validate how social media can be used to influence the adoption of healthy behaviours.</p>
                <ReactTooltip id="tooltip3" html={true} clickable={true} />

               <p>To support countries in the region in their social listening and infodemic management efforts, the UNICEF Regional Office for Europe and Central Asia started, in September 2020, a social listening project in partnership with the London School of Hygiene and Tropical Medicine, the Vaccine Confidence Project. Through this project, we are monitoring the digital space of 16 countries <sup class="pointer" data-class="custom-tooltip" data-tip={tooltip4} data-for="tooltip4">4</sup> and releasing periodical reports on COVID-19 and on immunization. The reports capture key conversation themes, topics of interest, top platforms and authors, and circulating rumours and misinformation. These insights can inform risk communication messaging and other evidence-based interventions aimed at increasing confidence in vaccines.</p> 
               <ReactTooltip id="tooltip4" html={true} clickable={true} />
            </div>

            <p><strong class="text-bold">INSIGHTS FROM THE SOCIAL LISTENING REPORTS</strong></p>
            <p><strong class="text-bold">Social Listening on Immunization - Regional Overview (October – December 2020)</strong></p>

            <div className="col2">
                <ul>
                    <li class="list">There was a very large increase in social media conversations around vaccines in all monitored countries starting in early November 2020. This was driven by interest in COVID vaccines and coincided with the announcement of positive efficacy data from Pfizer clinical trials in the first week of November.</li>
                    <li class="list">Conversation volumes continued to increase throughout November and December, with spikes around 8th December when the first COVID vaccine was administered in the UK, and 27th December when the EU vaccination programme commenced.</li>
                    <li class="list">The vast majority of the conversation around vaccines in all monitored countries was about COVID vaccines. Mentions about other vaccines, including DTP and measles-containing vaccines, were typically in the tens or low hundreds in most countries (France being the exception).</li>
                    <li class="list">In most countries, conversations closely followed major national and international news stories. News of prominent politicians and public figures getting their COVID-19 vaccines were a major talking point. In addition to national political figures (typically presidents, prime ministers, health ministers, etc), international news stories about Biden, Fauci, Netanyahu and others receiving their first doses cut through in many places.</li>
                    <li class="list">The main communication channels for vaccine conversations are largely the same as for COVID conversations. Facebook was dominant in 5 countries, Twitter in a further 5, VK in 4, and ok.ru just edged out Facebook in 1 country.</li>
                    <li class="list">Misinformation narratives were generally the ones already known – e.g. conspiracy theories around Bill Gates, population control, etc. In some countries, there were also a few posts with unsubstantiated claims about adverse events following vaccination. Rumours that vaccination will be made mandatory persisted in France and Romania, despite official statements to the contrary.</li>
                </ul>
            </div>

            <div class="full-w-grey">
                <img src={chart1} alt={`chart1`} />
            </div>

            <div class="full-w-grey">
                <img src={chart2} alt={`chart2`} />
            </div>

            <div className="col2">
    
                <p>The periodical social listening reports on immunization and COVID-19 can be found on the Regional Office <a class="custom-link" href="https://unicef.sharepoint.com/:f:/r/teams/ECAR-C4SC-Collab/Repository/Regional%20Initiative%20on%20Social%20Listening%20and%20Digital%20Engagement?csf=1&web=1&e=jwnbN1" target="_blank"> SharePoint.</a></p>

                <p class="mt-5"><strong class="text-bold">Addressing misinformation and tackling the infodemic</strong></p>

               <p>To help countries manage the infodemic, UNICEF ECARO has developed a <a href="https://www.unicef.org/eca/media/13636/file" target="_blank" class="custom-link">resource pack on addressing online misinformation</a>. The pack, which will be updated in Q2 2021, brings a list of resources on the topic and approaches that can be used to address misinformation. Since the pack’s release, more research has been done in this area of work, and additional insights and approaches to tackle the infodemic have been identified, such as:</p> 

                <ul>
                    <li class="list">Anti-vaccination supporters tweet less but engage more in discussions.<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip5} data-for="tooltip5">5</sup> At the same time, pro-vaccination individuals and groups are more prone to generate new content and are not very engaged with a broader community with similar interests. In this regard, it would be beneficial to build a community of engaged influencers that support the dissemination of scientific insights, including issues related to vaccines and their safety.</li>
                    <ReactTooltip id="tooltip5" html={true} clickable={true} />
                    <li class="list">Anti-vaccination profiles and groups online have been shown to generate content that is based on personal experiences and opinions, whereas pro-vaccination groups and institutions have the tendency to quote experts and cite scientific literature when sharing their views online.</li>
                    <li class="list">Exposure to social media is associated with misperceptions, whereas those who reported to have seen, read, or heard information on these topics in traditional media were more likely to respond accurately about vaccine-preventable diseases.</li>
                    <li class="list">According to a study conducted by The Alan Turing Institute, in the UK, individuals with lower digital literacy, numerical literacy, health literacy and cognitive skills are worse at assessing the veracity of health-related statements.<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip6} data-for="tooltip6">6</sup>  Additionally, a study published in the Harvard Kennedy School (HKS) Misinformation Review shows that those who reported low trust in medical authorities were the same ones who believed vaccine misinformation. <sup class="pointer" data-class="custom-tooltip" data-tip={tooltip7} data-for="tooltip7">7</sup></li>
                    <ReactTooltip id="tooltip6" html={true} clickable={true} />
                    <ReactTooltip id="tooltip7" html={true} clickable={true} />
                </ul>
            </div>
            
            <p class="mt-10"><strong class="text-bold">Programming considerations</strong></p>
            <div className="col2">
                <p>These findings suggest that vaccine communication should include messaging with emotional language with scientific information translated simply and appealingly. Additionally, they indicate that there should be efforts to educate the public through traditional sources, such as traditional media and engagement with health professionals, and facilitate access to verified information across various platforms, including social media. Moreover, it is crucial to consider that misinformation extrapolates the digital space and can affect even those who are not digitally connected. Therefore, strategies to increase vaccine confidence and tackle the infodemic should also target offline communities, foster digital and health literacy, and increase trust in medical authorities. Mechanisms to identify and track rumours and misinformation in the digital space and offline, including among health professionals should be considered.</p>
            </div>

            <h6 class="message-title-small mt-20 mb-10">USING BEHAVIOURAL INSIGHTS TO INCREASE VACCINE CONFIDENCE AND UPTAKE</h6>

            <div className="col2">
    
                <p>Vaccine acceptance is influenced by various factors, including behavioural determinants. Therefore, understanding the determinants of individual vaccination decisions is critical to establishing country-specific effective policies and strategies that aim to increase vaccine confidence and uptake.</p>

               <p>To design and pretest behavioural insights interventions to increase demand for immunization, UNICEF ECARO engaged with The Behavioural Insights Team (BIT), UK. The project, focused on Georgia and North Macedonia, started in August 2020, and is designed to take place in 3 main phases up until the end of June 2021:</p>

                <ul>
                    <li>1. <strong class="text-bold">Target phase: </strong>The main objective of this phase is to narrow down the focus of the research to specific behavioural challenges within each country.</li>
                    <li>2. <strong class="text-bold">Explore Phase: </strong>This phase involves both desk research and additional data collection in-country to understand the behavioural barriers along the childhood vaccination journey in Georgia and North Macedonia.</li>
                    <li>3. <strong class="text-bold">The ‘Solution’ phase </strong>involves taking a participatory, human-centred design approach to identify, pre-test and refine a set of evidence-based and scalable behavioural interventions to encourage childhood vaccination.</li>
                </ul>

                <p>Following the two initial phases – target and explore – it was decided to focus on <strong class="text-bold">reasing HPV vaccination in Georgia and increasing MMR1 vaccination in North Macedonia.</strong> </p>

                <p>Following qualitative research and interactive activities with the participation of key stakeholders, including caregivers, policymakers and health providers, the countries identified behavioural insights interventions and started pretesting prototypes.</p>

            </div>

            <div class="full-w-grey">
                <h6 class="message-title-small font-italic">Prototype solution – checklist, FAQ and clinic posters - tested in North Macedonia in cycle 1</h6>
                <img src={prototype1} alt={`prototype1`} />
            </div>

            <div class="full-w-grey">
                <h6 class="message-title-small font-italic">Prototype solution – a personalized email sent from pediatricians in polyclinics to eligible caregivers, informing them about HPV vaccine and reminding them to book an appointment - tested in Georgia in cycle 1</h6>
                <img src={prototype2} alt={`prototype2`} />
            </div>

            <div className="col2">
    
                <p>Georgia and North Macedonia are currently at different cycle stages of the pretesting activities. Once three cycles of pretesting are completed, a final report for each country will be developed, describing the activities and outputs from the assessment, solution development, and pre-testing phases. The final solutions will be described in detail, including steps required to implement them at a larger scale (including actions to be taken, context, roles, and responsibilities), along with potential approaches and methods for evaluating the efficacy and effectiveness of the proposed solutions.</p>

            </div>

            <h6 class="message-title-small mt-20 mb-10">STRENGTHENING HEALTH WORKERS’ INTERPERSONAL COMMUNICATION SKILLS FOR CHILDHOOD IMMUNIZATION AND COVID-19 VACCINATION</h6>

            <div className="col2">
    
                <p>Recognizing health workers' crucial role in addressing hesitancy and strengthening confidence in vaccines, the UNICEF Europe and Central Asia Regional Office (ECARO) has created a <a href="https://www.unicef.org/eca/reports/interpersonal-communication-immunization-facilitator-guide" target="_blank" class="custom-link">training package</a> on interpersonal communication (IPC) for immunization, which was also adapted to a <a href="https://www.unicef.org/eca/media/14246/file" class="custom-link" target="_blank">training for home visitors</a>. The training package developed and tested in collaboration with Johns Hopkins Centre for Communication Programmes in 2018 aims to strengthen healthcare professionals' capacity in the region to understand and influence positive change in vaccine hesitancy.</p>

               <p>Since the package's release, the world has faced an unprecedented challenge with the COVID-19 pandemic and disruptions in routine immunization services.</p> 

                <p>As plans for vaccination against COVID-19 emerge, the value of having a cadre of frontline workers training in IPC skills is more critical than ever. Hence, the Training of Trainers (TOT) package is being redesigned to bring specific aspects related to addressing hesitancy against COVID-19 vaccines and new relevant topics, such as addressing misinformation.</p>

                <p>Participants of the workshop will be prepared to function as master trainers in their respective countries. To allow the participation of all countries willing to take part in the training, ECARO organized three TOTs in April and May 2021. As a result 90 experts have new knowledge and are able to provide training on IPC.</p>

            </div>
          </article>
        </div>
    )
}

export default VaccineConfidence
