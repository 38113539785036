const MainMessages = () => {

  return (
    <div className="pages">
      <article>
        <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
        <h1 class="title">Main Messages</h1>
        <div className="col2">
            <div class="message">
                <h6 class="message-title">Immunization coverage with 3 doses of DTP vaccine dropped by 2% point in 2020 comparing with 2019</h6>
                <p>In 2020 280,000 children did not receive the three recommended doses of the DTP vaccine</p>
                <p class="message-note"><sup>*</sup>Note: WUENIC data includes only 14 ECAR countries Countries comprising less than 1% of regional share of unvaccinated children are not included in calculation</p>
            </div>
            <div class="message">
                <h6 class="message-title">Only one-third of countries reached and maintained 95% for DTP3</h6>
                <p>Only 7 countries reached and maintained 95% for DTP3</p>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
            <div class="message">
                <h6 class="message-title">9 countries have districts with DTP3 coverage below 80%</h6>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
            <div class="message">
                <h6 class="message-title">Measles regional coverage with first dose dropped in 2020 comparing with 2019</h6>
                <p>In 2020, the regional MCV1 coverage was 94%. This is a 3-percentage point drop from the previous year’s regional coverage (97%). National coverage data ranged from 24% to 99%.</p>
                <p>247,000 children still haven’t received their first dose of a measles containing vaccine</p>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
            <div class="message">
                <h6 class="message-title">More that half of the countries (14) have MCV2 coverage below 95%</h6>
                <p>10 countries have coverage with first dose of measles below 80% at district level</p>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
            <div class="message">
                <h6 class="message-title">Measles regional coverage with the second dose dropped by 1% point in 2020 comparing with 2019</h6>
                <p>More than 350,000 children remain unvaccinated for MCV2</p>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
            <div class="message">
                <h6 class="message-title">Over two third of countries have MCV2 coverage below 95%</h6>
                <p>10 countries have districts with MCV2 coverage below 80%</p>
                <p class="message-note"><sup>*</sup>Note: Wuenic and eJRF data</p>
            </div>
        </div>
      </article>
    </div>
  ) 
}

export default MainMessages
