import React, { useState } from 'react';
import Chart from "react-google-charts";

const originalDTP1DTP3HEPB3HIB3 = [
     ['Year', 'DTP1', { role: "style" }, 'DTP2', { role: "style" }, 'HEPB3', { role: "style" }, 'HIB3', { role: "style" }],
     [2000, 95, "#751a71", 93, "#3a419b", 33,"#00a0ea", 0, '#4dd2ff'],
     [2001, 96, "#751a71", 93, "#3a419b", 47,"#00a0ea", 0, '#4dd2ff'],
     [2002, 93, "#751a71", 91, "#3a419b", 71,"#00a0ea", 1, '#4dd2ff'],
     [2003, 91, "#751a71", 87, "#3a419b", 80,"#00a0ea", 1, '#4dd2ff'],
     [2004, 93, "#751a71", 92, "#3a419b", 88,"#00a0ea", 1, '#4dd2ff'],
     [2005, 96, "#751a71", 95, "#3a419b", 91,"#00a0ea", 1, '#4dd2ff'],
     [2006, 96, "#751a71", 94, "#3a419b", 91,"#00a0ea", 3, '#4dd2ff'],
     [2007, 98, "#751a71", 96, "#3a419b", 95,"#00a0ea", 24, '#4dd2ff'],
     [2008, 97, "#751a71", 96, "#3a419b", 93,"#00a0ea", 36, '#4dd2ff'],
     [2009, 95, "#751a71", 94, "#3a419b", 93,"#00a0ea", 51, '#4dd2ff'],
     [2010, 94, "#751a71", 93, "#3a419b", 92,"#00a0ea", 55, '#4dd2ff'],
     [2011, 94, "#751a71", 93, "#3a419b", 90,"#00a0ea", 58, '#4dd2ff'],
     [2012, 95, "#751a71", 95, "#3a419b", 92,"#00a0ea", 65, '#4dd2ff'],
     [2013, 96, "#751a71", 95, "#3a419b", 93,"#00a0ea", 65, '#4dd2ff'],
     [2014, 94, "#751a71", 91, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
     [2015, 95, "#751a71", 91, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
     [2016, 93, "#751a71", 90, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
     [2017, 95, "#751a71", 93, "#3a419b", 93,"#00a0ea", 61, '#4dd2ff'],
     [2018, 97, "#751a71", 95, "#3a419b", 95,"#00a0ea", 64, '#4dd2ff'],
     [2019, 97, "#751a71", 96, "#3a419b", 95,"#00a0ea", 66, '#4dd2ff'],
     [2020, 96, "#751a71", 94, "#3a419b", 94,"#00a0ea", 65, '#4dd2ff'],
    ];

const originalMCV1MCV2 = [
    ['Year', 'MCV1', { role: "style" }, 'MCV2', { role: "style" }],
    [2000, 93, "#751a71", 63, "#3a419b"],
    [2001, 94, "#751a71", 89, "#3a419b"],
    [2002, 92, "#751a71", 90, "#3a419b"],
    [2003, 91, "#751a71", 95, "#3a419b"],
    [2004, 92, "#751a71", 96, "#3a419b"],
    [2005, 95, "#751a71", 94, "#3a419b"],
    [2006, 96, "#751a71", 89, "#3a419b"],
    [2007, 97, "#751a71", 90, "#3a419b"],
    [2008, 97, "#751a71", 87, "#3a419b"],
    [2009, 95, "#751a71", 83, "#3a419b"],
    [2010, 94, "#751a71", 87, "#3a419b"],
    [2011, 95, "#751a71", 91, "#3a419b"],
    [2012, 95, "#751a71", 90, "#3a419b"],
    [2013, 96, "#751a71", 93, "#3a419b"],
    [2014, 93, "#751a71", 91, "#3a419b"],
    [2015, 94, "#751a71", 90, "#3a419b"],
    [2016, 93, "#751a71", 88, "#3a419b"],
    [2017, 96, "#751a71", 93, "#3a419b"],
    [2018, 96, "#751a71", 94, "#3a419b"],
    [2019, 97, "#751a71", 94, "#3a419b"],
    [2020, 94, "#751a71", 93, "#3a419b"],
    ];

const originalPOL3IPV1 = [
    ['Year', 'POL3', { role: "style" }, 'IPV1', { role: "style" }],
    [2000, 93, "#751a71", null, "#3a419b"],
    [2001, 94, "#751a71", null, "#3a419b"],
    [2002, 91, "#751a71", null, "#3a419b"],
    [2003, 88, "#751a71", null, "#3a419b"],
    [2004, 93, "#751a71", null, "#3a419b"],
    [2005, 95, "#751a71", null, "#3a419b"],
    [2006, 94, "#751a71", null, "#3a419b"],
    [2007, 96, "#751a71", null, "#3a419b"],
    [2008, 96, "#751a71", null, "#3a419b"],
    [2009, 95, "#751a71", null, "#3a419b"],
    [2010, 93, "#751a71", null, "#3a419b"],
    [2011, 93, "#751a71", null, "#3a419b"],
    [2012, 95, "#751a71", null, "#3a419b"],
    [2013, 95, "#751a71", null, "#3a419b"],
    [2014, 92, "#751a71", null, "#3a419b"],
    [2015, 93, "#751a71", 68, "#3a419b"],
    [2016, 93, "#751a71", 72, "#3a419b"],
    [2017, 93, "#751a71", 70, "#3a419b"],
    [2018, 95, "#751a71", 88, "#3a419b"],
    [2019, 95, "#751a71", 95, "#3a419b"],
    [2020, 95, "#751a71", 94, "#3a419b"],
    ];

const originalPCV3ROTAC = [
    ['Year', 'PCV3', { role: "style" }, 'ROTAC', { role: "style" }],
    [2000, null, "#751a71", null, "#3a419b"],
    [2001, null, "#751a71", null, "#3a419b"],
    [2002, null, "#751a71", null, "#3a419b"],
    [2003, null, "#751a71", null, "#3a419b"],
    [2004, null, "#751a71", null, "#3a419b"],
    [2005, null, "#751a71", null, "#3a419b"],
    [2006, null, "#751a71", 0, "#3a419b"],
    [2007, null, "#751a71", 0, "#3a419b"],
    [2008, 0, "#751a71", 0, "#3a419b"],
    [2009, 21, "#751a71", 0, "#3a419b"],
    [2010, 21, "#751a71", 0, "#3a419b"],
    [2011, 22, "#751a71", 0, "#3a419b"],
    [2012, 24, "#751a71", 0, "#3a419b"],
    [2013, 25, "#751a71", 1, "#3a419b"],
    [2014, 27, "#751a71", 7, "#3a419b"],
    [2015, 30, "#751a71", 16, "#3a419b"],
    [2016, 55, "#751a71", 17, "#3a419b"],
    [2017, 67, "#751a71", 17, "#3a419b"],
    [2018, 72, "#751a71", 16, "#3a419b"],
    [2019, 76, "#751a71", 12, "#3a419b"],
    [2020, 75, "#751a71", 20, "#3a419b"],
    ];

const dataDTP1DTP3HEPB3HIB3 = [
    ['Year', 'DTP1', { role: "style" }, 'DTP2', { role: "style" }, 'HEPB3', { role: "style" }, 'HIB3', { role: "style" }],
    [2000, 95, "#751a71", 93, "#3a419b", 33,"#00a0ea", 0, '#4dd2ff'],
    [2001, 96, "#751a71", 93, "#3a419b", 47,"#00a0ea", 0, '#4dd2ff'],
    [2002, 93, "#751a71", 91, "#3a419b", 71,"#00a0ea", 1, '#4dd2ff'],
    [2003, 91, "#751a71", 87, "#3a419b", 80,"#00a0ea", 1, '#4dd2ff'],
    [2004, 93, "#751a71", 92, "#3a419b", 88,"#00a0ea", 1, '#4dd2ff'],
    [2005, 96, "#751a71", 95, "#3a419b", 91,"#00a0ea", 1, '#4dd2ff'],
    [2006, 96, "#751a71", 94, "#3a419b", 91,"#00a0ea", 3, '#4dd2ff'],
    [2007, 98, "#751a71", 96, "#3a419b", 95,"#00a0ea", 24, '#4dd2ff'],
    [2008, 97, "#751a71", 96, "#3a419b", 93,"#00a0ea", 36, '#4dd2ff'],
    [2009, 95, "#751a71", 94, "#3a419b", 93,"#00a0ea", 51, '#4dd2ff'],
    [2010, 94, "#751a71", 93, "#3a419b", 92,"#00a0ea", 55, '#4dd2ff'],
    [2011, 94, "#751a71", 93, "#3a419b", 90,"#00a0ea", 58, '#4dd2ff'],
    [2012, 95, "#751a71", 95, "#3a419b", 92,"#00a0ea", 65, '#4dd2ff'],
    [2013, 96, "#751a71", 95, "#3a419b", 93,"#00a0ea", 65, '#4dd2ff'],
    [2014, 94, "#751a71", 91, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
    [2015, 95, "#751a71", 91, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
    [2016, 93, "#751a71", 90, "#3a419b", 91,"#00a0ea", 61, '#4dd2ff'],
    [2017, 95, "#751a71", 93, "#3a419b", 93,"#00a0ea", 61, '#4dd2ff'],
    [2018, 97, "#751a71", 95, "#3a419b", 95,"#00a0ea", 64, '#4dd2ff'],
    [2019, 97, "#751a71", 96, "#3a419b", 95,"#00a0ea", 66, '#4dd2ff'],
    [2020, 96, "#751a71", 94, "#3a419b", 94,"#00a0ea", 65, '#4dd2ff'],
    ];

const dataMCV1MCV2 = [
    ['Year', 'MCV1', { role: "style" }, 'MCV2', { role: "style" }],
    [2000, 93, "#751a71", 63, "#3a419b"],
    [2001, 94, "#751a71", 89, "#3a419b"],
    [2002, 92, "#751a71", 90, "#3a419b"],
    [2003, 91, "#751a71", 95, "#3a419b"],
    [2004, 92, "#751a71", 96, "#3a419b"],
    [2005, 95, "#751a71", 94, "#3a419b"],
    [2006, 96, "#751a71", 89, "#3a419b"],
    [2007, 97, "#751a71", 90, "#3a419b"],
    [2008, 97, "#751a71", 87, "#3a419b"],
    [2009, 95, "#751a71", 83, "#3a419b"],
    [2010, 94, "#751a71", 87, "#3a419b"],
    [2011, 95, "#751a71", 91, "#3a419b"],
    [2012, 95, "#751a71", 90, "#3a419b"],
    [2013, 96, "#751a71", 93, "#3a419b"],
    [2014, 93, "#751a71", 91, "#3a419b"],
    [2015, 94, "#751a71", 90, "#3a419b"],
    [2016, 93, "#751a71", 88, "#3a419b"],
    [2017, 96, "#751a71", 93, "#3a419b"],
    [2018, 96, "#751a71", 94, "#3a419b"],
    [2019, 97, "#751a71", 94, "#3a419b"],
    [2020, 94, "#751a71", 93, "#3a419b"],
    ];

const dataPOL3IPV1 = [
    ['Year', 'POL3', { role: "style" }, 'IPV1', { role: "style" }],
    [2000, 93, "#751a71", null, "#3a419b"],
    [2001, 94, "#751a71", null, "#3a419b"],
    [2002, 91, "#751a71", null, "#3a419b"],
    [2003, 88, "#751a71", null, "#3a419b"],
    [2004, 93, "#751a71", null, "#3a419b"],
    [2005, 95, "#751a71", null, "#3a419b"],
    [2006, 94, "#751a71", null, "#3a419b"],
    [2007, 96, "#751a71", null, "#3a419b"],
    [2008, 96, "#751a71", null, "#3a419b"],
    [2009, 95, "#751a71", null, "#3a419b"],
    [2010, 93, "#751a71", null, "#3a419b"],
    [2011, 93, "#751a71", null, "#3a419b"],
    [2012, 95, "#751a71", null, "#3a419b"],
    [2013, 95, "#751a71", null, "#3a419b"],
    [2014, 92, "#751a71", null, "#3a419b"],
    [2015, 93, "#751a71", 68, "#3a419b"],
    [2016, 93, "#751a71", 72, "#3a419b"],
    [2017, 93, "#751a71", 70, "#3a419b"],
    [2018, 95, "#751a71", 88, "#3a419b"],
    [2019, 95, "#751a71", 95, "#3a419b"],
    [2020, 95, "#751a71", 94, "#3a419b"],
    ];

const dataPCV3ROTAC = [
    ['Year', 'PCV3', { role: "style" }, 'ROTAC', { role: "style" }],
    [2000, null, "#751a71", null, "#3a419b"],
    [2001, null, "#751a71", null, "#3a419b"],
    [2002, null, "#751a71", null, "#3a419b"],
    [2003, null, "#751a71", null, "#3a419b"],
    [2004, null, "#751a71", null, "#3a419b"],
    [2005, null, "#751a71", null, "#3a419b"],
    [2006, null, "#751a71", 0, "#3a419b"],
    [2007, null, "#751a71", 0, "#3a419b"],
    [2008, 0, "#751a71", 0, "#3a419b"],
    [2009, 21, "#751a71", 0, "#3a419b"],
    [2010, 21, "#751a71", 0, "#3a419b"],
    [2011, 22, "#751a71", 0, "#3a419b"],
    [2012, 24, "#751a71", 0, "#3a419b"],
    [2013, 25, "#751a71", 1, "#3a419b"],
    [2014, 27, "#751a71", 7, "#3a419b"],
    [2015, 30, "#751a71", 16, "#3a419b"],
    [2016, 55, "#751a71", 17, "#3a419b"],
    [2017, 67, "#751a71", 17, "#3a419b"],
    [2018, 72, "#751a71", 16, "#3a419b"],
    [2019, 76, "#751a71", 12, "#3a419b"],
    [2020, 75, "#751a71", 20, "#3a419b"],
    ];

const RegionalTrends = () => {

    const [showDTP1DTP3HEPB3HIB3, setshowDTP1DTP3HEPB3HIB3] = useState(true);
    const handleClickshowDTP1DTP3HEPB3HIB3 = () => {
        setshowDTP1DTP3HEPB3HIB3(true)
        if(!showDTP1DTP3HEPB3HIB3) {
            setshowMCV1MCV2(false); 
            setshowPCV3ROTAC(false);
            setshowPOL3IPV1(false); 
        } else {
           
        }
    }

    let [showMCV1MCV2, setshowMCV1MCV2] = useState(false);
    const handleClickshowMCV1MCV2 = () => {
        setshowMCV1MCV2(true)
        if(!showMCV1MCV2) {
            setshowDTP1DTP3HEPB3HIB3(false); 
            setshowPCV3ROTAC(false);
            setshowPOL3IPV1(false);
        } else {
             
        }
    }

    let [showPOL3IPV1, setshowPOL3IPV1] = useState(false);
    const handleClickshowPOL3IPV1 = () => {
        setshowPOL3IPV1(true)
        if(!showPOL3IPV1) {
            setshowMCV1MCV2(false); 
            setshowPCV3ROTAC(false);
            setshowDTP1DTP3HEPB3HIB3(false); 
        } else {
            
        }
    }

    let [showPCV3ROTAC, setshowPCV3ROTAC] = useState(false);
    const handleClickshowPCV3ROTAC = () => {
        setshowPCV3ROTAC(true)
        if(!showPCV3ROTAC) {
            setshowMCV1MCV2(false); 
            setshowDTP1DTP3HEPB3HIB3(false);
            setshowPOL3IPV1(false);
        } else {
             
        }
    }

    let [showDTP1, setshowDTP1] = useState(true);
    const handleClickshowDTP1 = () => {
        setshowDTP1(!showDTP1)
        if(!showDTP1) {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {  
                if(i != 0) {
                    element[1] = originalDTP1DTP3HEPB3HIB3[i][1]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showDTP3, setshowDTP3] = useState(true);
    const handleClickshowDTP3 = () => {
        setshowDTP3(!showDTP3)
        if(!showDTP3) {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {  
                if(i != 0) {
                    element[3] = originalDTP1DTP3HEPB3HIB3[i][3]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {
                if(i != 0) {
                    element[3] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showHEPB3, setshowHEPB3] = useState(true);
    const handleClickshowHEPB3 = () => {
        setshowHEPB3(!showHEPB3)
        if(!showHEPB3) {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {  
                if(i != 0) {
                    element[5] = originalDTP1DTP3HEPB3HIB3[i][5]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {
                if(i != 0) {
                    element[5] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showHIB3, setshowHIB3] = useState(true);
    const handleClickshowHIB3 = () => {
        setshowHIB3(!showHIB3)
        if(!showHIB3) {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {  
                if(i != 0) {
                    element[7] = originalDTP1DTP3HEPB3HIB3[i][7]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataDTP1DTP3HEPB3HIB3.forEach(element => {
                if(i != 0) {
                    element[7] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showMCV1, setshowMCV1] = useState(true);
    const handleClickshowMCV1 = () => {
        setshowMCV1(!showMCV1)
        if(!showMCV1) {
            let i = 0; 
            dataMCV1MCV2.forEach(element => {  
                if(i != 0) {
                    element[1] = originalMCV1MCV2[i][1]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataMCV1MCV2.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showMCV2, setshowMCV2] = useState(true);
    const handleClickshowMCV2 = () => {
        setshowMCV2(!showMCV2)
        if(!showMCV2) {
            let i = 0; 
            dataMCV1MCV2.forEach(element => {  
                if(i != 0) {
                    element[3] = originalMCV1MCV2[i][3]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataMCV1MCV2.forEach(element => {
                if(i != 0) {
                    element[3] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showPOL3, setshowPOL3] = useState(true);
    const handleClickshowPOL3 = () => {
        setshowPOL3(!showPOL3)
        if(!showPOL3) {
            let i = 0; 
            dataPOL3IPV1.forEach(element => {  
                if(i != 0) {
                    element[1] = originalPOL3IPV1[i][1]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataPOL3IPV1.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showIPV1, setshowIPV1] = useState(true);
    const handleClickshowIPV1 = () => {
        setshowIPV1(!showIPV1)
        if(!showIPV1) {
            let i = 0; 
            dataPOL3IPV1.forEach(element => {  
                if(i != 0) {
                    element[3] = originalPOL3IPV1[i][3]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataPOL3IPV1.forEach(element => {
                if(i != 0) {
                    element[3] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showPCV3, setshowPCV3] = useState(true);
    const handleClickshowPCV3 = () => {
        setshowPCV3(!showPCV3)
        if(!showPCV3) {
            let i = 0; 
            dataPCV3ROTAC.forEach(element => {  
                if(i != 0) {
                    element[1] = originalPCV3ROTAC[i][1]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataPCV3ROTAC.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    let [showROTAC, setshowROTAC] = useState(true);
    const handleClickshowROTAC = () => {
        setshowROTAC(!showROTAC)
        if(!showROTAC) {
            let i = 0; 
            dataPCV3ROTAC.forEach(element => {  
                if(i != 0) {
                    element[3] = originalPCV3ROTAC[i][3]; 
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            dataPCV3ROTAC.forEach(element => {
                if(i != 0) {
                    element[3] = 0; 
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    return (
        <div className="pages">
            <article>
                <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
                <h1 class="title">Regional vaccination trends, 2000-2020</h1> 
                <div className="mt-20 filter-div">
                    <div className="flex items-center pr-6">
                        <div className="pr-2 text-xl">DTP1 / DTP3 / HEPB3 / HIB3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickshowDTP1DTP3HEPB3HIB3} checked={showDTP1DTP3HEPB3HIB3} type="checkbox" id="DTP1DTP3HEPB3HIB3"/>
                                <label htmlFor="DTP1DTP3HEPB3HIB3"></label>
                            </div>
                        </div>
                        <div className="pl-20 pr-2 text-xl">MCV1 / MCV2</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickshowMCV1MCV2} checked={showMCV1MCV2} type="checkbox" id="MCV1MCV2"/>
                                <label htmlFor="MCV1MCV2"></label>
                            </div>
                        </div>
                        <div className="pl-20 pr-2 text-xl">POL3 / IPV1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickshowPOL3IPV1} checked={showPOL3IPV1} type="checkbox" id="POL3IPV1"/>
                                <label htmlFor="POL3IPV1"></label>
                            </div>
                        </div>
                        <div className="pl-20 pr-2 text-xl">PCV3 / ROTAC</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickshowPCV3ROTAC} checked={showPCV3ROTAC} type="checkbox" id="PCV3ROTAC"/>
                                <label htmlFor="PCV3ROTAC"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-w-grey">
                { showDTP1DTP3HEPB3HIB3 &&
                <Chart
                    chartType="LineChart"
                    data={dataDTP1DTP3HEPB3HIB3}
                    options={{
                        backgroundColor: '#F0F0F0',
                        height: '80vh',
                        width: '100%',
                        legend: { position: 'none'},
                        hAxis: {
                            ticks: [2000, 2005, 2010, 2015, 2020],
                            format: '0',
                            textStyle: {
                                color: "#3e4c6d"
                            },
                        },
                        vAxis: {
                            title: 'Coverage (%)',
                            titleTextStyle: {
                                color: "#3e4c6d"
                            },  
                            textStyle: {
                                color: "#3e4c6d"
                            }
                        }
                    }}
                    />
                }
                { showMCV1MCV2 &&
                <Chart
                    chartType="LineChart"
                    data={dataMCV1MCV2}
                    options={{
                        backgroundColor: '#F0F0F0',
                        height: '80vh',
                        width: '100%',
                        legend: { position: 'none'},
                        hAxis: {
                            ticks: [2000, 2005, 2010, 2015, 2020],
                            format: '0',
                            textStyle: {
                                color: "#3e4c6d"
                            },
                        },
                        vAxis: {
                            title: 'Coverage (%)',
                            titleTextStyle: {
                                color: "#3e4c6d"
                            },  
                            textStyle: {
                                color: "#3e4c6d"
                            }
                        }
                    }}
                    />
                }
                { showPOL3IPV1 &&
                <Chart
                    chartType="LineChart"
                    data={dataPOL3IPV1}
                    options={{
                        backgroundColor: '#F0F0F0',
                        height: '80vh',
                        width: '100%',
                        legend: { position: 'none'},
                        hAxis: {
                            ticks: [2000, 2005, 2010, 2015, 2020],
                            format: '0',
                            textStyle: {
                                color: "#3e4c6d"
                            },
                        },
                        vAxis: {
                            title: 'Coverage (%)',
                            titleTextStyle: {
                                color: "#3e4c6d"
                            },  
                            textStyle: {
                                color: "#3e4c6d"
                            }
                        }
                    }}
                    />
                }
                { showPCV3ROTAC &&
                <Chart
                    chartType="LineChart"
                    data={dataPCV3ROTAC}
                    options={{
                        backgroundColor: '#F0F0F0',
                        height: '80vh',
                        width: '100%',
                        legend: { position: 'none'},
                        hAxis: {
                            ticks: [2000, 2005, 2010, 2015, 2020],
                            format: '0',
                            textStyle: {
                                color: "#3e4c6d"
                            },
                        },
                        vAxis: {
                            title: 'Coverage (%)',
                            titleTextStyle: {
                                color: "#3e4c6d"
                            },  
                            textStyle: {
                                color: "#3e4c6d"
                            }
                        }
                    }}
                    />
                }
                { showDTP1DTP3HEPB3HIB3 &&
                <div className="align-center display-table">
                    <div className="flex items-center pr-6">
                        <div className="mr-2 pr-2 text-xl background-main pl-2 pt-1 text-white text-bold">DTP1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input class onClick={handleClickshowDTP1} defaultChecked={showDTP1} type="checkbox" id="DTP1"/>
                                <label htmlFor="DTP1"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-dark-blue pl-2 pt-1 text-white text-bold">DTP3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickshowDTP3} defaultChecked={showDTP3} type="checkbox" id="DTP3"/>
                                <label htmlFor="DTP3"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-blue pl-2 pt-1 text-white text-bold">HEPB3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-blue">
                                <input onClick={handleClickshowHEPB3} defaultChecked={showHEPB3} type="checkbox" id="HEPB3"/>
                                <label htmlFor="HEPB3"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-light-blue pl-2 pt-1 text-white text-bold">HIB3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-light-blue">
                                <input onClick={handleClickshowHIB3} defaultChecked={showHIB3} type="checkbox" id="HIB3"/>
                                <label htmlFor="HIB3"></label>
                            </div>
                        </div>
                    </div>
                </div>
                }

                { showMCV1MCV2 &&
                <div className="align-center display-table">
                    <div className="flex items-center pr-6">
                        <div className="mr-2 pr-2 text-xl background-main pl-2 pt-1 text-white text-bold">MCV1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input class onClick={handleClickshowMCV1} defaultChecked={showMCV1} type="checkbox" id="MCV1"/>
                                <label htmlFor="MCV1"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-dark-blue pl-2 pt-1 text-white text-bold">MCV2</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickshowMCV2} defaultChecked={showMCV2} type="checkbox" id="MCV2"/>
                                <label htmlFor="MCV2"></label>
                            </div>
                        </div>
                    </div>
                </div>
                }

                { showPOL3IPV1 &&
                <div className="align-center display-table">
                    <div className="flex items-center pr-6">
                        <div className="mr-2 pr-2 text-xl background-main pl-2 pt-1 text-white text-bold">POL3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input class onClick={handleClickshowPOL3} defaultChecked={showPOL3} type="checkbox" id="POL3"/>
                                <label htmlFor="POL3"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-dark-blue pl-2 pt-1 text-white text-bold">IPV1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickshowIPV1} defaultChecked={showIPV1} type="checkbox" id="IPV1"/>
                                <label htmlFor="IPV1"></label>
                            </div>
                        </div>
                    </div>
                </div>
                }

                { showPCV3ROTAC &&
                <div className="align-center display-table">
                    <div className="flex items-center pr-6">
                        <div className="mr-2 pr-2 text-xl background-main pl-2 pt-1 text-white text-bold">PCV3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input class onClick={handleClickshowPCV3} defaultChecked={showPCV3} type="checkbox" id="PCV3"/>
                                <label htmlFor="PCV3"></label>
                            </div>
                        </div>
                        <div className="ml-10 mr-2 pr-2 text-xl background-dark-blue pl-2 pt-1 text-white text-bold">ROTAC</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickshowROTAC} defaultChecked={showROTAC} type="checkbox" id="ROTAC"/>
                                <label htmlFor="ROTAC"></label>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <p class="text-xs mt-5">ECA Regional coverage data is based on countries with WUENIC data</p>
                </div>
            </article>
        </div>
    )
}

export default RegionalTrends
