import TableCountries from '../../page_elements/tables/TableCountries';

const VaccinationCoverageCountry = () => {

    return (
        <div className="pages">
            <article>
                <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
                <h1 class="title">Vaccination coverage by country, 2020</h1>
                <TableCountries />
                <p class="mt-10 text-xs lh-5">Source: WUENIC and eJRFWUENIC is used for all countries but: Albania, Armenia, BiH, Kosovo, Greece and Serbia, in which case admin (eJRF) data is used eJRF countries marked with (*)</p>
                <p class="text-xs lh-5">No 2020 data for: Croatia and North Macedonia</p>
                <p class="text-xs lh-5">HPV figures are taken from “population who received the last dose of HPV vaccine in the reporting year”</p>
            </article>
        </div>
    )
}

export default VaccinationCoverageCountry
