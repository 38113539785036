import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from './pages/Home'
import Background1 from './images/back1.jpg'
import Header from './components/Header'
import Social from './components/Social'
import MainMessages from './pages/main_messages/MainMessages'
import VaccinationCoverage from './pages/vaccination_coverage/VaccinationCoverage'
import VaccinationCoverageCountry from './pages/vaccination_coverage_country/VaccinationCoverageCountry'
import RegionalTrends from './pages/regional_trends/RegionalTrends'
import Top5Countries from './pages/top_5_countries/Top5Countries'
import Top10Countries from './pages/top_10_countries/Top10Countries'
import LookingAtImmunization from './pages/looking_at_immunization/LookingAtImmunization'
import ImmunizationCovid from './pages/immunization_covid/ImmunizationCovid'
import VaccineConfidence from './pages/vaccine_confidence/VaccineConfidence'
import HowUnicef from './pages/how_unicef/HowUnicef'
import GoTop from './components/GoTop'

function App() {
  return (
    <Router> 
        <Header />
          <Switch>
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/regional-immunization-snapshot-main-messages">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <MainMessages />
                <GoTop />
            </Route>
            <Route path="/regional-immunization-snapshot-vaccination-coverage">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
              <VaccinationCoverage />
              <GoTop />
            </Route>
            <Route path="/regional-immunization-snapshot-vaccination-coverage-country">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <VaccinationCoverageCountry />
                <GoTop />
            </Route>
            <Route path="/regional-immunization-snapshot-regional-trends">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <RegionalTrends />
                <GoTop />
            </Route>
            <Route path="/regional-immunization-snapshot-top-5-countries">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <Top5Countries />
                <GoTop />
            </Route>
            <Route path="/regional-immunization-snapshot-top-10-countries">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <Top10Countries />
                <GoTop />
            </Route>
            <Route path="/looking-at-immunization-coverage-at-subnational-level">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <LookingAtImmunization />
                <GoTop />
            </Route>
            <Route path="/immunization-in-the-contect-of-covid-19">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <ImmunizationCovid />
                <GoTop />
            </Route>
            <Route path="/vaccine-confidence-and-infodemic-management">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <VaccineConfidence />
                <GoTop />
            </Route>
            <Route path="/how-unicef-europe-and-central-asia-regions-supports-immunization-programs">
                <div className="w-screen bg-no-repeat" style={{objectFit: 'contain', backgroundPositionX: "70%", backgroundSize:"cover", height: '200px', backgroundImage: 'url(' + Background1 + ')'}}></div>
                <HowUnicef />
                <GoTop />
            </Route>
          </Switch>

        <Social />
    </Router>
  );
}

export default App;
