import Chart from "react-google-charts";
import React, { useState } from 'react';

const VaccinationCoverage = () => {

    let dataDTP1 = [
        ['Country', 'DTP1 Coverage', 'Unvaccinated Children', {type:'string', role:'tooltip'}],
        ['Albania', null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Armenia', null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Azerbaijan', 85, 23000, "DTP1 Coverage: 85% \n Unvaccinated Children: 23000"],
        ['Belarus', 97, 3000, "DTP1 Coverage: 97% \n Unvaccinated Children: 3000"],
        ['Bosnia and Herzegovina', null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Bulgaria', 94, 4000, "DTP1 Coverage: 94% \n Unvaccinated Children: 4000"],
        ['Croatia', null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Georgia', 95, 3000, "DTP1 Coverage: 95% \n Unvaccinated Children: 3000"],
        ['Greece', null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Kazakhstan', 94, 22000, "DTP1 Coverage: 94% \n Unvaccinated Children: 22000"],
        [{v: "XK", f: "Kosovo"}, null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Kyrgyzstan', 90, 15000, "DTP1 Coverage: 90% \n Unvaccinated Children: 15000"],
        ['Moldova', 86, 5000, "DTP1 Coverage: no 86% \n Unvaccinated Children: 5000"],
        ['Montenegro', 94, null, "DTP1 Coverage: 94% \n Unvaccinated Children: no data"],
        [{v: "MK", f: "North Macedonia"}, null, null, "DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Romania', 95, 9000, "DTP1 Coverage: 95% \n Unvaccinated Children: 9000"],
        ['Serbia', null, null,"DTP1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Tajikistan', 98, 5000, "DTP1 Coverage: 98% \n Unvaccinated Children: 5000"],
        ['Turkey', 99, 13000, "DTP1 Coverage: 99% \n Unvaccinated Children: 13000"],
        ['Turkmenistan', 99,null, "DTP1 Coverage: 99% \n Unvaccinated Children: no data"],
        ['Ukraine', 93, 28000, "DTP1 Coverage: 93% \n Unvaccinated Children: 28000"],
        ['Uzbekistan', 96, 27000, "DTP1 Coverage: 96% \n Unvaccinated Children: 27000"]
    ]

    let dataDTP3 = [
        ['Country', 'DTP3 Coverage', 'Unvaccinated Children', {type:'string', role:'tooltip'}],
        ['Albania', 99, null, "DTP3 Coverage: 99% \n Unvaccinated Children: no data"],
        ['Armenia', null, null, "DTP3 Coverage: no data \n Unvaccinated Children: no data"],
        ['Azerbaijan', 79, 32000, "DTP3 Coverage: 79% \n Unvaccinated Children: 32000"],
        ['Belarus', 97, 3000, "DTP3 Coverage: 97% \n Unvaccinated Children: 3000"],
        ['Bosnia and Herzegovina', 63, null, "DTP3 Coverage: 63% \n Unvaccinated Children: no data"],
        ['Bulgaria', 91, 5000,"DTP3 Coverage: 91% \n Unvaccinated Children: 5000"],
        ['Croatia', null, null, "DTP3 Coverage: no data \n Unvaccinated Children: no data"],
        ['Georgia', 88, 6000, "DTP3 Coverage: 88% \n Unvaccinated Children: 6000"],
        ['Greece', 100, null, "DTP3 Coverage: 100% \n Unvaccinated Children: no data"],
        ['Kazakhstan', 88, 44000, "DTP3 Coverage: 88% \n Unvaccinated Children: 44000"],
        [{v: "XK", f: "Kosovo"}, null, null, "DTP3 Coverage: no data \n Unvaccinated Children: no data"],
        ['Kyrgyzstan', 87, 19000, "DTP3 Coverage: 87% \n Unvaccinated Children: 19000"],
        ['Moldova', 86, 5000, "DTP3 Coverage: 86% \n Unvaccinated Children: 5000"],
        ['Montenegro', 84, null, "DTP3 Coverage: 84% \n Unvaccinated Children: no data"],
        [{v: "MK", f: "North Macedonia"}, null, null, "DTP3 Coverage: no data \n Unvaccinated Children: no data"],
        ['Romania', 87, 24000, "DTP3 Coverage: 87% \n Unvaccinated Children: 24000"],
        ['Serbia', 92, null, "DTP3 Coverage: 92% \n Unvaccinated Children: no data"],
        ['Tajikistan', 97, 8000, "DTP3 Coverage: 97% \n Unvaccinated Children: 8000"],
        ['Turkey', 98, 26000, "DTP3 Coverage: 98% \n Unvaccinated Children: 26000"],
        ['Turkmenistan', 98, null, "DTP3 Coverage: 98% \n Unvaccinated Children: no data"],
        ['Ukraine', 81, 75000, "DTP3 Coverage: 81% \n Unvaccinated Children: 75000"],
        ['Uzbekistan', 95, 33000, "DTP3 Coverage: 95% \n Unvaccinated Children: 33000"]
    ]

    let dataMCV1 = [
        ['Country', 'MCV1 Coverage', 'Unvaccinated Children', {type:'string', role:'tooltip'}],
        ['Albania', 91, null, "MCV1 Coverage: 91% \n Unvaccinated Children: no data"],
        ['Armenia', null, null, "MCV1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Azerbaijan', 82, 28000, "MCV1 Coverage: 82% \n Unvaccinated Children: 28000"],
        ['Belarus', 97, 3000, "MCV1 Coverage: 97% \n Unvaccinated Children: 3000"],
        ['Bosnia and Herzegovina', 52, null, "MCV1 Coverage: 52% \n Unvaccinated Children: no data"],
        ['Bulgaria', 88, 7000, "MCV1 Coverage: 88% \n Unvaccinated Children: 7000"],
        ['Croatia', null, null, "MCV1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Georgia', 91, 5000, "MCV1 Coverage: 91% \n Unvaccinated Children: 5000"],
        ['Greece', 97, null, "MCV1 Coverage: 97% \n Unvaccinated Children: no data"],
        ['Kazakhstan', 93, 26000, "MCV1 Coverage: 93% \n Unvaccinated Children: 26000"],
        [{v: "XK", f: "Kosovo"}, null, null, "MCV1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Kyrgyzstan', 92, 12000, "MCV1 Coverage: 92% \n Unvaccinated Children: 12000"],
        ['Moldova', 84, 6000, "MCV1 Coverage: 84% \n Unvaccinated Children: 6000"],
        ['Montenegro', 24, null, "MCV1 Coverage: 24% \n Unvaccinated Children: no data"],
        [{v: "MK", f: "North Macedonia"}, null, null, "MCV1 Coverage: no data \n Unvaccinated Children: no data"],
        ['Romania', 87, 24000, "MCV1 Coverage: 87% \n Unvaccinated Children: 24000"],
        ['Serbia', 78, null, "MCV1 Coverage: 78% \n Unvaccinated Children: no data"],
        ['Tajikistan', 98, 5000, "MCV1 Coverage: 98% \n Unvaccinated Children: 5000"],
        ['Turkey', 95, 65000, "MCV1 Coverage: 95% \n Unvaccinated Children: 65000"],
        ['Turkmenistan', 98, null, "MCV1 Coverage: 98% \n Unvaccinated Children: no data"],
        ['Ukraine', 85, 59000, "MCV1 Coverage: 85% \n Unvaccinated Children: 59000"],
        ['Uzbekistan', 99, 7000, "MCV1 Coverage: 99% \n Unvaccinated Children: 7000"]
    ]

    let dataMCV2 = [
        ['Country', 'MCV2 Coverage', 'Unvaccinated Children', {type:'string', role:'tooltip'}],
        ['Albania', 94, null, "MCV2 Coverage: 94% \n Unvaccinated Children: no data"],
        ['Armenia', null, null, "MCV2 Coverage: no data \n Unvaccinated Children: no data"],
        ['Azerbaijan', 79, 37000, "MCV2 Coverage: 79% \n Unvaccinated Children: 37000"],
        ['Belarus', 98, null, "MCV2 Coverage: 98% \n Unvaccinated Children: no data"],
        ['Bosnia and Herzegovina', 49, null, "MCV2 Coverage: 49% \n Unvaccinated Children: no data"],
        ['Bulgaria', 84, 12000, "MCV2 Coverage: 84% \n Unvaccinated Children: 12000"],
        ['Croatia', null, null, "MCV2 Coverage: no data \n Unvaccinated Children: no data"],
        ['Georgia', 77, 13000, "MCV2 Coverage: 77% \n Unvaccinated Children: 13000"],
        ['Greece', 83, null, "MCV2 Coverage: 83% \n Unvaccinated Children: no data"],
        ['Kazakhstan', 91, 36000, "MCV2 Coverage: 91% \n Unvaccinated Children: 36000"],
        [{v: "XK", f: "Kosovo"}, null, null, "MCV2 Coverage: no data \n Unvaccinated Children: no data"],
        ['Kyrgyzstan', 93, 11000, "MCV2 Coverage: 93% \n Unvaccinated Children: 11000"],
        ['Moldova', 93, null, "MCV2 Coverage: 93% \n Unvaccinated Children: no data"],
        ['Montenegro', 76, null, "MCV2 Coverage: 76% \n Unvaccinated Children: no data"],
        [{v: "MK", f: "North Macedonia"}, null, null, "MCV2 Coverage: no data \n Unvaccinated Children: no data"],
        ['Romania', 75, 46000, "MCV2 Coverage: 75% \n Unvaccinated Children: 46000"],
        ['Serbia', 84, null, "MCV2 Coverage: 84% \n Unvaccinated Children: no data"],
        ['Tajikistan', 97, 8000, "MCV2 Coverage: 97% \n Unvaccinated Children: 8000"],
        ['Turkey', 93, 95000, "MCV2 Coverage: 93% \n Unvaccinated Children: 95000"],
        ['Turkmenistan', 99, null, "MCV2 Coverage: 99% \n Unvaccinated Children: no data"],
        ['Ukraine', 82, 89000, "MCV2 Coverage: 82% \n Unvaccinated Children: 89000"],
        ['Uzbekistan', 99, 7000, "MCV2 Coverage: 99% \n Unvaccinated Children: 7000"]
    ]


    const [showDTP1, setShowDTP1] = useState(true);
    const handleClickShowDTP1 = () => {
        setShowDTP1(!showDTP1)
        if(!showDTP1) {
            setShowDTP3(false); 
            setShowMCV1(false);
            setShowMCV2(false); 
        } else {
            setShowDTP1(true); 
        }
    }

    const [showDTP3, setShowDTP3] = useState(false);
    const handleClickShowDTP3 = () => {
        setShowDTP3(!showDTP3)
        if(!showDTP3) {
            setShowDTP1(false); 
            setShowMCV1(false);
            setShowMCV2(false); 
        } else {
            setShowDTP3(true); 
        }
    }

    const [showMCV1, setShowMCV1] = useState(false);
    const handleClickShowMCV1 = () => {
        setShowMCV1(!showMCV1)
        if(!showMCV1) {
            setShowDTP3(false); 
            setShowDTP1(false);
            setShowMCV2(false); 
        } else {
            setShowMCV1(true); 
        }
    }

    const [showMCV2, setShowMCV2] = useState(false);
    const handleClickShowMCV2 = () => {
        setShowMCV2(!showMCV2)
        if(!showMCV2) {
            setShowDTP3(false); 
            setShowMCV1(false);
            setShowDTP1(false); 
        } else {
            setShowMCV2(true); 
        }
    }

    return (
        <div className="pages">
            <article>
                <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
                <div class="col2">
                    <h1 class="title">Vaccination coverage and number of infants not vaccinated for DTP1, DTP3 (under-vaccinated), MCV1 and MCV2, 2020</h1>
                    <p>Countries in light green have the highest vaccination coverage ranging between 95% to 100%. While the countries in red have the lowest coverage range (0% to 79%).</p>
                    <p>Countries having districts with coverage below 80% for DTP3, MCV1 and/or MCV2 are represented by having its name with a purple background.</p>
                </div>
                <div className="mt-20 filter-div">
                    <div className="flex items-center pr-6">
                        <div className="text-xl">Coverage and number of infants not vaccinated for DTP1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickShowDTP1} defaultChecked={showDTP1} checked={showDTP1} type="checkbox" id="DTP1"/>
                                <label htmlFor="DTP1"></label>
                            </div>
                        </div>
                        <div className="pl-20 text-xl">Coverage and number of infants not vaccinated for DTP3 (under-vaccinated)</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickShowDTP3} defaultChecked={showDTP3} checked={showDTP3} type="checkbox" id="DTP3"/>
                                <label htmlFor="DTP3"></label>
                            </div>
                        </div>
                        <div className="pl-20 text-xl">Coverage and number of infants not vaccinated for MCV1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickShowMCV1} defaultChecked={showMCV1} checked={showMCV1} type="checkbox" id="MCV1"/>
                                <label htmlFor="MCV1"></label>
                            </div>
                        </div>
                        <div className="pl-20 text-xl">Coverage and number of infants not vaccinated for MCV2</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickShowMCV2} defaultChecked={showMCV2} checked={showMCV2} type="checkbox" id="MCV2"/>
                                <label htmlFor="MCV2"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-w-grey">
                    <p class="text-xs text-main">Roll the mouse over the country to visualize the number of infants not vacinated</p>
                    { showDTP1 &&
                    <div class="col2">
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '150',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataDTP1}
                        />
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '142',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataDTP1}
                        />
                    </div>
                    }

                    { showDTP3 &&
                    <div class="col2">
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '150',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataDTP3}
                        />
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '142',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataDTP3}
                        />
                    </div>
                    }

                    { showMCV1 &&
                    <div class="col2">
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '150',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataMCV1}
                        />
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '142',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataMCV1}
                        />
                    </div>
                    }

                    { showMCV2 &&
                    <div class="col2">
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '150',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataMCV2}
                        />
                        <Chart className="geochart"
                            chartType="GeoChart"
                            var options={{
                                defaultColor: '#D7D8D9',
                                backgroundColor: 'transparent',
                                datalessRegionColor: 'transparent',
                                displayMode: 'regions',
                                region: '142',
                                legend: 'none',
                                chartArea:{left:100,top:100,width:'50%'},
                                colorAxis: {
                                    values:[0, 1, 79, 80, 94, 95],
                                    colors:['#d7d8d9', '#f30000', '#f30000', '#ffc100', '#ffc100', '#75bc00']
                                },
                            }}
                            data={dataMCV2}
                        />
                    </div>
                    }
                    { showDTP3 &&
                        <p class="text-l text-white bg-main pl-2 mt-10">Countries having districts with coverages below 80%: Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Montenegro, Romania, Serbia, Ukraine</p>
                    }

                    { showMCV1 &&
                        <p class="text-l text-white bg-main pl-2 mt-10">Countries having districts with coverages below 80%: Azerbaijan, Bulgaria, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Montenegro, Romania, Serbia, Ukraine</p>
                    }

                    { showMCV2 &&
                        <p class="text-l text-white bg-main pl-2 mt-10">Countries having districts with coverages below 80%: Azerbaijan, Bulgaria, Georgia, Kazakhstan, Moldova, Montenegro, Romania, Serbia, Tajikistan, Turkey, Ukraine</p>
                    }
                    <div class="float-left mr-10 mt-10 mb-5"><div class="legend-red"></div><span class="pl-2">0% - 79%</span></div>
                    <div class="float-left mr-10 mt-10 mb-5"><div class="legend-yellow"></div><span class="pl-2">80% - 94%</span></div>
                    <div class="float-left mr-10 mt-10 mb-5"><div class="legend-green"></div><span class="pl-2">95% - 100%</span></div>
                    <div class="mt-10 mb-5"><div class="legend-grey"></div><span class="pl-2">No data available</span></div>
                    <p class="text-xs mb-5"><strong class="text-bold">Note:</strong> This map is not to scale. It does not reflect a position by UNICEF on the legal status of any country or area or the delimitation of any frontiers.</p>
                    <p class="text-xs lh-5">Source: WUENIC and eJRF</p>
                    <p class="text-xs lh-5">WUENIC is used for all countries but: Albania, Armenia, BiH, Kosovo, Greece and Serbia, in which case admin (eJRF) data is used eJRF countries marked with (*)</p>
                    <p class="text-xs lh-5">Subnational estimates of unvaccinated children are based on countries with WUENIC data, except Serbia</p>
                </div>


               
            </article>
        </div>
    )
}

export default VaccinationCoverage
