import { Link } from "react-router-dom";

import PropTypes from 'prop-types'

const NavSubItem = ({ title, linkTo, navSubActive, setShowNav }) => {
    return (
        <li className="m-2">
            { navSubActive ? 
                <div
                    className="
                        py-1 mb-1 
                        text-bold
                    "
                >
                { title } 
                </div>
            : 
                <Link
                    to={ linkTo }
                    onClick={() => setShowNav(false)}
                    className="
                        py-1 mb-1 
                        ani03s cursor-pointer
                    " 
                >
                    { title }
                </Link>
            }
        </li>
    )
}

NavSubItem.propTypes = {
    title: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
}

export default NavSubItem
