import PropTypes from 'prop-types'

const SocialLink = ({ socialIcon, socialUrl }) => {
  return (
      <a href={socialUrl} className="relative flex flex-col justify-center items-center w-7 h-7 m-1 bg-main hover:bg-main rounded-full ani03s" rel="noreferrer" target="_blank">
          <span className={`absolute top-0.5 text-white text-lg ${socialIcon}`}></span>
      </a>
  )
}

SocialLink.propTypes = {
  socialIcon: PropTypes.string.isRequired,
  socialUrl: PropTypes.string.isRequired,
}

export default SocialLink