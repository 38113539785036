import React, { useState } from 'react';
import Chart from "react-google-charts";

const original = [
    ["Country", "DTP1", { role: "style" }, { role: 'annotation' }, "DTP3", { role: "style" }, { role: 'annotation' }, "MCV1", { role: "style" }, { role: 'annotation' }, "MCV2", { role: "style" }, { role: 'annotation' }],
    ["AZERBAIJAN", 23000, "#751a71", 23000, 32000,"#00a0ea", 32000, 28000,"#3a419b", 28000, 37000, "#75bc00", 37000],
    ["KAZAKHSTAN", 22000, "#751a71", 22000, 44000,"#00a0ea", 44000, 26000,"#3a419b", 26000, 36000, "#75bc00", 36000],
    ["ROMANIA", null, "#751a71", null, null,"#00a0ea", null, 24000,"#3a419b", 24000, 46000, "#75bc00", 46000],
    ["TURKEY", 13000,"#751a71", 13000, 26000,"#00a0ea", 26000, 65000,"#3a419b", 65000, 95000, "#75bc00", 95000],
    ["UKRAINE", 28000,"#751a71", 28000, 75000,"#00a0ea", 75000, 59000,"#3a419b", 59000, 89000, "#75bc00", 89000],
    ["UZBEKISTAN", 27000,"#751a71", 27000, 33000,"#00a0ea", 33000, null,"#3a419b", null, null, "#75bc00",null],
];

const data = [
    ["Country", "DTP1", { role: "style" }, { role: 'annotation' }, "DTP3", { role: "style" }, { role: 'annotation' }, "MCV1", { role: "style" }, { role: 'annotation' }, "MCV2", { role: "style" }, { role: 'annotation' }],
    ["AZERBAIJAN", 23000, "#751a71", 23000, 32000,"#00a0ea", 32000, 28000,"#3a419b", 28000, 37000, "#75bc00", 37000],
    ["KAZAKHSTAN", 22000, "#751a71", 22000, 44000,"#00a0ea", 44000, 26000,"#3a419b", 26000, 36000, "#75bc00", 36000],
    ["ROMANIA", null, "#751a71", null, null,"#00a0ea", null, 24000,"#3a419b", 24000, 46000, "#75bc00", 46000],
    ["TURKEY", 13000,"#751a71", 13000, 26000,"#00a0ea", 26000, 65000,"#3a419b", 65000, 95000, "#75bc00", 95000],
    ["UKRAINE", 28000,"#751a71", 28000, 75000,"#00a0ea", 75000, 59000,"#3a419b", 59000, 89000, "#75bc00", 89000],
    ["UZBEKISTAN", 27000,"#751a71", 27000, 33000,"#00a0ea", 33000, null,"#3a419b", null, null, "#75bc00",null],
];

const Top5Countries = () => {

   
    const [showDTP1, setshowDTP1] = useState(true);
    const handleClickshowDTP1 = () => {
        setshowDTP1(!showDTP1)
        if(!showDTP1) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[1] = original[i][1]; 
                    element[3] = original[i][3];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    element[3] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    const [showDTP3, setshowDTP3] = useState(true);
    const handleClickshowDTP3 = () => {
        setshowDTP3(!showDTP3)
        if(!showDTP3) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[4] = original[i][4]; 
                    element[6] = original[i][6];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[4] = 0; 
                    element[6] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    const [showMCV1, setshowMCV1] = useState(true);
    const handleClickshowMCV1 = () => {
        setshowMCV1(!showMCV1)
        if(!showMCV1) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[7] = original[i][9]; 
                    element[9] = original[i][9];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[7] = 0; 
                    element[9] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    const [showMCV2, setshowMCV2] = useState(true);
    const handleClickshowMCV2 = () => {
        setshowMCV2(!showMCV2)
        if(!showMCV2) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[10] = original[i][10]; 
                    element[12] = original[i][12];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[10] = 0; 
                    element[12] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    return (
        <div className="pages">
            <article>
                <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
                <h1 class="title">Top 5 countries with the most infants not vaccinated for DTP1(un-vaccinated), not vaccinated for DTP3 (under-vaccinated) and not vaccinated for MCV1 and MCV2, 2020</h1> 
                <div className="mt-20 filter-div">
                    <div className="flex items-center pr-6">
                        <div className="pr-2 text-xl text-main">DTP1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickshowDTP1} defaultChecked={showDTP1} type="checkbox" id="DTP1"/>
                                <label htmlFor="DTP1"></label>
                            </div>
                        </div>
                        <div className="pl-10 pr-2 text-xl text-blue">DTP3</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-blue">
                                <input onClick={handleClickshowDTP3} defaultChecked={showDTP3} type="checkbox" id="DTP3"/>
                                <label htmlFor="DTP3"></label>
                            </div>
                        </div>
                        <div className="pl-10 pr-2 text-xl text-dark-blue">MCV1</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickshowMCV1} defaultChecked={showMCV1} type="checkbox" id="MCV1"/>
                                <label htmlFor="MCV1"></label>
                            </div>
                        </div>
                        <div className="pl-10 pr-2 text-xl text-green">MCV2</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-green">
                                <input onClick={handleClickshowMCV2} defaultChecked={showMCV2} type="checkbox" id="MCV2"/>
                                <label htmlFor="MCV2"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-w-grey">
                    <Chart
                        chartType="ColumnChart"
                        data={data}
                        options={{
                            backgroundColor: '#F0F0F0',
                            height: '80vh',
                            width: '100%',
                            legend: { position: 'none'},
                            chartArea:{left:100,top:100,width:'100%',height:'75%'},
                            vAxis: { 
                                ticks: [0, 20000, 40000, 60000, 80000, 100000],
                                textStyle: {
                                    color: "#3e4c6d"
                                }
                            },
                            annotations: {
                                alwaysOutside: true,
                                textStyle: {
                                    color: "#3e4c6d", 
                                    bold: true
                                }
                            },
                            hAxis: {  
                                textStyle: {
                                    bold: true,
                                    color: "#3e4c6d"
                                },
                            }
                        }}
                        />
                    <p class="text-xs">Based on WUENIC data</p> 
                </div>
            </article>
        </div>
    )
}

export default Top5Countries
