import React, { useState } from 'react';
import Chart from "react-google-charts";

const data = [
    ["Country", "2020", { role: "style" }, { role: 'annotation' }, "2019", { role: "style" }, { role: 'annotation' }, "2018", { role: "style" }, { role: 'annotation' }],
    ["AZERBAIJAN", 32, "#751a71", 32, 10,"#00a0ea", 10, 8,"#3a419b", 80],
    ["BOSNIA AND HERZEGOVINA", 7, "#751a71", 7, 7,"#00a0ea", 7, 7,"#3a419b", 7],
    ["GEORGIA", 6, "#751a71", 6, 3,"#00a0ea", 3, 4,"#3a419b", 4],
    ["KAZAKHSTAN", 44,"#751a71", 44, 11,"#00a0ea", 11, 8,"#3a419b", 8],
    ["KYRGYZSTAN", 19,"#751a71", 19, 8,"#00a0ea", 8, 9,"#3a419b", 9],
    ["ROMANIA", 24,"#751a71", 24, 22,"#00a0ea", 22, 26,"#3a419b", 26],
    ["TAJIKISTAN", 8,"#751a71", 8, 8,"#00a0ea", 8, 11,"#3a419b", 11],
    ["TURKEY", 26,"#751a71", 26, 13,"#00a0ea", 13, 26,"#3a419b", 26],
    ["UKRAINE", 75,"#751a71", 75, 81,"#00a0ea", 81, 130,"#3a419b", 130],
    ["UZBEKISTAN", 33,"#751a71", 33, 27,"#00a0ea", 27, 14,"#3a419b", 14],
];

const original = [
    ["Country", "2020", { role: "style" }, { role: 'annotation' }, "2019", { role: "style" }, { role: 'annotation' }, "2018", { role: "style" }, { role: 'annotation' }],
    ["AZERBAIJAN", 32, "#751a71", 32, 10,"#00a0ea", 10, 8,"#3a419b", 80],
    ["BOSNIA AND HERZEGOVINA", 7, "#751a71", 7, 7,"#00a0ea", 7, 7,"#3a419b", 7],
    ["GEORGIA", 6, "#751a71", 6, 3,"#00a0ea", 3, 4,"#3a419b", 4],
    ["KAZAKHSTAN", 44,"#751a71", 44, 11,"#00a0ea", 11, 8,"#3a419b", 8],
    ["KYRGYZSTAN", 19,"#751a71", 19, 8,"#00a0ea", 8, 9,"#3a419b", 9],
    ["ROMANIA", 24,"#751a71", 24, 22,"#00a0ea", 22, 26,"#3a419b", 26],
    ["TAJIKISTAN", 8,"#751a71", 8, 8,"#00a0ea", 8, 11,"#3a419b", 11],
    ["TURKEY", 26,"#751a71", 26, 13,"#00a0ea", 13, 26,"#3a419b", 26],
    ["UKRAINE", 75,"#751a71", 75, 81,"#00a0ea", 81, 130,"#3a419b", 130],
    ["UZBEKISTAN", 33,"#751a71", 33, 27,"#00a0ea", 27, 14,"#3a419b", 14],
];

const Top10Countries = () => {

    const [show2020, setShow2020] = useState(true);
    const handleClickShow2020 = () => {
        setShow2020(!show2020)
        if(!show2020) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[1] = original[i][1]; 
                    element[3] = original[i][3];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[1] = 0; 
                    element[3] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    const [show2019, setShow2019] = useState(true);
    const handleClickShow2019 = () => {
        setShow2019(!show2019)
        if(!show2019) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[4] = original[i][4]; 
                    element[6] = original[i][6];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[4] = 0; 
                    element[6] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    const [show2018, setShow2018] = useState(true);
    const handleClickShow2018 = () => {
        setShow2018(!show2018)
        if(!show2018) {
            let i = 0; 
            data.forEach(element => {  
                if(i != 0) {
                    element[7] = original[i][7]; 
                    element[9] = original[i][9];
                    i++; 
                } else {
                    i++;
                }
            });
        } else {
            let i = 0; 
            data.forEach(element => {
                if(i != 0) {
                    element[7] = 0; 
                    element[9] = null;
                    i++; 
                } else {
                    i++; 
                }
            });
        }
    }

    return (
        <div className="pages">
            <article>
                <h2 class="title-section">REGIONAL IMMUNIZATION SNAPSHOT</h2>
                <h1 class="title">Top 10 countries with the most infants not vaccinated for DTP3, 2018-2020</h1> 
                <div className="mt-20 filter-div">
                    <div className="flex items-center pr-6">
                        <div className="pr-2 text-xl text-main">2020</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-main">
                                <input onClick={handleClickShow2020} defaultChecked={show2020} type="checkbox" id="2020"/>
                                <label htmlFor="2020"></label>
                            </div>
                        </div>
                        <div className="pl-10 pr-2 text-xl text-blue">2019</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-blue">
                                <input onClick={handleClickShow2019} defaultChecked={show2019} type="checkbox" id="2019"/>
                                <label htmlFor="2019"></label>
                            </div>
                        </div>
                        <div className="pl-10 pr-2 text-xl text-dark-blue">2018</div>
                        <div className="round_checkbox_box">
                            <div className="center bg-dark-blue">
                                <input onClick={handleClickShow2018} defaultChecked={show2018} type="checkbox" id="2018"/>
                                <label htmlFor="2018"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <div class="full-w-grey">
                <Chart
                    chartType="ColumnChart"
                    data={data}
                    options={{
                        backgroundColor: '#F0F0F0',
                        height: '80vh',
                        width: '100%',
                        legend: { position: 'none'},
                        chartArea:{left:100,top:100,width:'100%',height:'75%'},
                        vAxis: { 
                            title: "Number of unvaccinated infants (in thousands)",
                            ticks: [0, 30, 60, 90, 120, 150],
                            titleTextStyle: {
                                color: "#3e4c6d"
                            },
                            textStyle: {
                                color: "#3e4c6d"
                            }
                        },
                        annotations: {
                            alwaysOutside: true,
                            textStyle: {
                                color: "#3e4c6d", 
                                bold: true
                            }
                        },
                        hAxis: {
                            textStyle: {
                                bold: true,
                                color: '#3e4c6d'
                            },
                        }
                    }}
                    />
                <p class="text-xs lh-5">Source: WHO/UNICEF Estimates of Nartional Immunization Coverage, 2020.</p> 
                <p class="text-xs lh-5">The Wrrold Population Prospects - 2019 revision, UN Population Division, New York, USA.</p> 
            </div>
        </div>
    )
}

export default Top10Countries
