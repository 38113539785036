const HowUnicef = () => {

  return (
    <div className="pages">
      <article>
        <h2 class="title-section">HOW UNICEF EUROPE AND CENTRAL ASIA REGION SUPPORTS IMMUNIZATION PROGRAMS</h2>
        <h1 class="title">What UNICEF does to support immunization programs strengthening</h1>
        <div className="col2">

            <p>Strong and responsive health systems are needed to deliver vaccines and to improve immunization coverage and equity. UNICEF is supporting governments in the ECA region to reinforce immunization programmes, as part of health system strengthening, to ensure that they are well organized and well financed to reach out to every child with life-saving vaccines.</p>
            <p>Immunization is one of UNICEF's regional flagship results aimed at achieving the following goal:</p>
            <p><strong class="text-bold">By 2030, all countries in the region have 95% of children at national level and at least 80% in every district vaccinated with DTP/Penta 3.</strong></p>
            <p>UNICEF works with countries in the region to support Governments to achieve the following results:</p>
            <ul>
                <li class="list">Countries have sustained and evidence-based immunization programmes at national and district levels to ensure universal and equitable immunization coverage.</li>
                <li class="list">National and district level health authorities have improved mechanisms for data collection and analysis to support evidence-based planning, budgeting and programme implementation.</li>
                <li class="list">CHealth professionals have improved capacities to deliver quality immunization services and strengthen demand for immunization.</li>
                <li class="list">Health authorities at national and district levels have capacities to timely and adequately respond to health and immunization-related crises and emergencies.</li>
                <li class="list">Increased use of immunization services by addressing vaccine hesitancy andincreasing demand for immunization.</li>
            </ul>
            <p>To achieve the proposed results, UNICEF will build on past successes, strengthen the evidence-base, and explore innovative approaches and use of existing partnerships with international (WHO; GAVI) and national stakeholders. Governments’ ownership and accountability will be at the centre of every intervention.</p>
            <p>UNICEF support varies from country to country and it is focused on the following system components:</p>
            
            <ul>
                <li class="list"><strong class="text-bold">Strengthening leadership, governance and sustainable financing</strong>. UNICEF supports governments in the revision and development of immunization strategies and regulatory frameworks to ensure transparent decision making and improve accountability in planning and implementation of immunization programmes. Support is provided to strengthen Intersectoral Coordination to improve collaboration between difference sectors and their contributions to health results. UNICEF supports Governments to analyze immunization budgets to guide budget allocations.</li>

                <li class="list"><strong class="text-bold">Improving the evidence base and building strong and responsive immunization information systems</strong>. UNICEF works closely with national and district health authorities to improve data collection and reporting systems, including information systems and electronic registers. This supports evidence-based decision making and development of tailored immunization programmes according to the needs of the population. UNICEF supports countries to undertake evidence reviews to analyze and document underlying and structural factors leading to the decrease in immunization rates and develop and implement informed response actions. Research to understand the magnitude and the drivers of vaccine hesitancy in specific countries and at sub-national levels are implemented.</li>

                <li class="list"><strong class="text-bold">Strengthening supply chain and procurement capacities</strong>. In collaboration with the Supply Division, UNICEF works closely with governments to identify critical supply chain bottlenecks and support their strengthening by increasing capacity on planning, forecasting, procurement and supply chain management.</li>

                <li class="list"><strong class="text-bold">Building the health workforce for quality service delivery and immunization promotion</strong>. UNICEF works in close cooperation with health authorities, academia and training institutions to ensure that a skilled, engaged and empowered workforce is available in immunization programmes. Efforts are directed towards improving the training and supportive supervision programmes for health professionals to improve the quality of immunization services and to increase public trust in immunization and health systems. UNICEF supports strengthening the capacity of frontline health workers on interpersonal communication on immunization and community engagement to address vaccine hesitancy.</li>

                <li class="list"><strong class="text-bold">Strengthening resilience and emergency response capacities for disease outbreaks and public health emergencies</strong>. UNICEF supports countries in developing emergency preparedness and crisis communication strategies to ensure better preparedness and response actions. Capacity building initiatives are undertaken at national and subnational levels on disease outbreak preparedness and response, ensuring accountability to affected populations is integrated.</li>

                <li class="list"><strong class="text-bold">Strengthening Health Sector capacity for demand creation</strong>. UNICEF supports countries to better understand and respond to vaccine hesitancy and anti-vaccination groups and movements across traditional and online channels. Social listening platforms/mechanisms are tested, helping health authorities to monitor social dialogue around vaccines and better prevent and respond to crises. Strategies to reach out to the most excluded groups to ensure individuals understand the value of immunization and demand vaccination are implemented. UNICEF is also supporting mainstreaming demand for immunization into national health policies and budgets.</li>
            </ul>
        </div>
      </article>
    </div>
  ) 
}

export default HowUnicef
