import kosovo from '../../images//kosovo.jpg'
import bosnia from '../../images//bosnia.jpg'
import moldova from '../../images//moldova.jpg'
import kyrgyzstan from '../../images//kyrgyzstan.jpg'
import uzbekistan from '../../images//uzbekistan.jpg'
import uzbekistan2 from '../../images//uzbekistan2.jpg'

const ImmunizationCovid = () => {

    return (
        <div className="pages">
          <article>
            <h2 class="title-section">IMMUNIZATION IN THE CONTEXT OF COVID-19: STORIES FROM THE FIELD</h2>
            <h1 class="title">Stories from the field</h1>
            <div className="col2">
    
                <p>Health systems have been stretched by the COVID-19 pandemic. Routine immunization services were hit early on and were badly affected due to travel restrictions, lack of personal protective measures and re-distribution of health workers to the COVID-19 response. Caregivers were also scared to visit health facilities. Five countries in the region temporarily suspended vaccinations at the beginning of the crisis. Although immunization services have now been fully restored across the region, 90 per cent of countries report a decrease in immunization coverage of between 3 to 20 per cent compared to pre-COVID-19 times, indicating that thousands of children are exposed to vaccine-preventable diseases.</p>
                
                <p>UNICEF country offices supported Governments to restore and maintain immunization services. Below are some stories from the field reflecting the work done at country level.</p>
            </div>

            <div class="separator"></div>

            <h1 class="title">Support to restoring immunization services in Kosovo</h1>
            <img className="w-full stories" src={kosovo} alt={`kosovo`} />
            <p class="image-credit">© UNICEF/Kosovo</p>
            <div class="legend">
                <p>UNICEF/2020/L. Aliu Mother Rozafa holding</p>
                <p>18-month old Rea while she gets her vaccine.</p>
            </div>
            <div className="col2">
    
                <p>Between March and May 2020, routine childhood immunization services were disrupted in Kosovo. However, with efforts from the MOH, Institute of Public Health, and support from UNICEF, vaccination services resumed on May 4 th 2020. Over 60 immunization tents have been set up in different Kosovar municipalities outside of health facilities to minimize risk of infection amongst children and their caregivers. To ensure the safest delivery and adherence to COVID-19 physical-distancing protocols, health workers have been provided with PPE, and services were offered daily until 13:00h. Since the resumption of childhood vaccination activities, approximately 37 children have been vaccinated daily.</p>
                
                <p>UNICEF continues to support the National Institute of Public Health and local health authorities to organize catch-up vaccination for children among Roma, Ashkali and Egyptian communities and children from marginalized families in 12 selected municipalities in Kosovo. In collaboration with local NGOs since October 2020, UNICEF supported the campaign through door-to-door identification of children with incomplete vaccination records. The number of children vaccinated during the catch-up immunization sessions is 1,389, while the number of children reached, and immunization records verified through door-to-door visits is 5,306. In addition, UNICEF supported the National Institute of Public Health on digitalization of routine immunization and COVID-19 vaccination data collection within the Health Information System.</p>
            </div>

            <div class="separator"></div>

            <h1 class="title">Campaign to raise awareness about vaccines and encourage catch up on missed immunizations in Moldova</h1>
            <img className="w-full stories" src={moldova} alt={`moldova`} />
            <p class="image-credit">© UNICEF/Moldova</p>
            <div className="col2">
    
                <p>Following disruption of immunization services from March to mid-May 2020 a 10% decrease in coverage was recorded during the first 3 quarters of 2020 compared to the same time period in 2019. Immunization services have since resumed, however, to bridge this gap in coverage it was critical to ensure the catch up of missed vaccinations. In efforts to do so, the Ministry of Health, Labor and Social Protection; the National Public Health Agency; WHO; and UNICEF together, in August launched a range of outreach activities to remind, encourage and educate caregivers to participate and catch up with routine immunizations.</p>
                
                <p>A national 1-year awareness-raising campaign — Vaccinează copiii, protejează-ți viitorul! (Vaccinate children, protect your future!) – was launched in the Republic of Moldova. The campaign aimed to address misperceptions, increase uptake, and encourage catch-up of routine and missed vaccinations. The campaign materials are based on results from qualitative focus-group discussions on immunization perceptions, beliefs and concerns to be addressed during the campaign. The evidence-based communication materials (official webpage, a series of videos and infographics) on vaccines’ benefits, use and ingredients were developed in Russian and Romanian. One of the campaign’s supporters, a well-known comedian and social media influencer, developed a video to promote the campaign. The video has reached over 1 million views.</p>
            </div>

            <div class="separator"></div>

            <h1 class="title">UNICEF support to maintain routine vaccination efforts in Uzbekistan during the COVID-19 pandemic</h1>
            <img className="w-full stories" src={uzbekistan} alt={`uzbekistan`} />
            <p class="image-credit">© UNICEF/Uzbekistan</p>
            <div className="col2">
    
                <p>To prevent any dangerous disruption of immunization services during the pandemic, UNICEF, with the support of the Global Alliance for Vaccines and Immunizations (GAVI) procured and delivered personal protective equipment to sanitary and epidemiological wellbeing centres in every region of the country. In total, 820,000 boxes of disposable masks, 25,000 boxes of medical gloves, 8,000 gowns and caps, 4000 litres of hand sanitizer and 130 kgs of disinfectant powder were handed over. As a result, more than 4,000 health workers including 3,000 vaccinators from primary health facilities are now equipped to keep both themselves and their patients safe.</p>
            </div>
            <img className="w-full stories" src={uzbekistan2} alt={`uzbekistan`} />
            <p class="image-credit">© UNICEF/Uzbekistan</p>
            <div className="col2">
                <p>In addition, with support from GAVI, UNICEF donated 223 vehicles to the Ministry of Health of Uzbekistan to ensure the quality of routine vaccines and transport them safely.</p>

                <p>Thanks to the joint efforts of the Ministry of Health, UNICEF, WHO, and GAVI, routine vaccination continued during the pandemic in Uzbekistan.</p>
            </div>

            <div class="separator"></div>

            <h1 class="title">In Bosnia and Herzegovina, UNICEF is working to ensure access to health services for the most vulnerable children along with immunization system support</h1>
            <img className="w-full stories" src={bosnia} alt={`bosnia`} />
            <p class="image-credit">©UNICEF/Bosnia Herzegovina</p>
            <div className="col2">
    
                <p>Since the onset of the COVID-19 outbreak in Bosnia and Herzegovina (March 2020), UNICEF Country Office supported the government and Roma NGOs to reduce the negative impact of the pandemic on routine immunization coverage. Activities included: risk communication, outbreak response, catch-up immunization planning, reaching Roma communities, and communication for social change. The latter included maintaining an interactive web platform (blog vakcine.ba) to address the anti-vaccine sentiment and restore overall trust in immunization.</p>
                
                <p>In addition, UNICEF Country Office ensured the provision of pediatric services to refugee and migrant children in four Temporary Reception Centers. They worked to increase awareness on the importance of immunization to parents of refugee/migrant children by offering culturally appropriate content, and provided immunization services to 1-18-year-olds. Over 300 refugee and migrant children were immunized in 2020 and over 1000 children received quality pediatric services, including immunization counseling.</p>

                <p>In addition, UNICEF supported the Government on cold chain assessment and development of / improvement of the procurement plan. Thanks to UNICEF, refrigerators to store COVID-19 vaccines were distributed to the FBiH Public Health Institute and RS Public Health Institute, East Sarajevo, Trebinje and Zvornik and the cantonal institutes of public health in Sarajevo, Bihac and Tuzla.</p>

                <p>These refrigerators are primarily intended for the storage of vaccines such as Pfizer BioNTech, which must be stored at -70<sup>o</sup>C.</p>

                <p>Vaccines will be stored in installed and verified refrigerators at a temperature of -80<sup>o</sup>C until they are sent to ambulances, health centers and other institutions where vaccination will be performed in accordance with the vaccination plan.</p>
            </div>

            <div class="separator"></div>

            <h1 class="title">In Kyrgyzstan, UNICEF supports strengthening the cold chain system to ensure quality immunization services across the country</h1>
            <img className="w-full stories" src={kyrgyzstan} alt={`kyrgyzstan`} />
            <p class="image-credit">© UNICEF/Kyrgyzstan</p>
            <div className="col2">
    
                <p>With financial support from the Vaccine Alliance (GAVI) and in partnership with WHO, UNICEF is supporting the Ministry of Health of the Kyrgyz Republic to strengthen and improve immunization programs throughout the country. Within the framework of this cooperation, 973 specialized refrigerators for vaccines were distributed, installed and put into operation, including in remote areas. In 2019, 632 refrigerators were delivered and installed at vaccination sites, and in 2020, despite problems with COVID-19, 341 refrigerators were installed across the country (see the <a class="custom-link" href="https://twitter.com/UNICEF_ECA/status/1356617273881018371?s=20" target="_blank">link</a>). This is an important step in supporting the government's efforts to close gaps in the cold chain to improve immunization coverage across the country. With the installation of dedicated vaccine coolers, 90% of vaccination sites in the country now have dedicated vaccine storage refrigeration equipment that can maintain the required vaccine temperature for more than 50 hours after a power outage. These refrigerators are especially important in remote areas where power outages are frequent. The use of these refrigerators will ensure equal access to safe immunization services for children living in remote areas and ensure that no child is left behind.</p>
            </div>
          </article>
        </div>
    )
}

export default ImmunizationCovid
