import romania from '../../images//romania.jpg'
import montenegro from '../../images//montenegro.jpg'
import ReactTooltip from 'react-tooltip';

let tooltip1 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MDA' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MDA</a>";
let tooltip2 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MNE' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MNE</a>"; 
let tooltip3 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=BIH' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=BIH</a>"; 
let tooltip4 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=ROU' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=ROU</a>"; 
let tooltip5 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=BIH' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=BIH</a>"; 
let tooltip6 = "<a href='https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MNE' target='_blank'>https://apps.who.int/immunization_monitoring/globalsummary/countries?countrycriteria%5Bcountry%5D%5B%5D=MNE</a>"

const LookingAtImmunization = () => {

    return (
        <div className="pages">
          <article>
            <h2 class="title-section">LOOKING AT IMMUNIZATION COVERAGE AT SUBNATIONAL LEVEL</h2>
            <div className="col2">
    
                <p>National coverage data often conceals large inequalities in coverage and access within the country, that can be discovered through subnational monitoring. Targeting specific subnational areas with focused interventions will help countries achieve high and equitable coverage and meet their targets. Even countries having high immunization coverage at national level might have districts/regions with suboptimal immunization coverage. Overall, 40% of countries in the region have districts with below 80% of immunization coverage.</p>
                
                <p>UNICEF and WHO are increasing efforts to gather subnational coverage data and support countries in improving its quality and use to take actions to achieve high and equitable immunization coverage. To understand better the bottlenecks leading to low immunization performance, UNICEF Europe and Central Asia Regional Office (ECARO) supported priority countries: Bosnia and Herzegovina, Moldova, Montenegro, North Macedonia, Romania and Ukraine to perform root cause analysis of low immunization coverage at subnational level and development of costed improvement plans. This included a desk review of existing evidence, a series of workshops and validation meetings with public health experts and service providers as well as focus group discussions with beneficiaries. Below are the main bottlenecks identified during the root cause analysis and main recommendations for improvement. The full reports can be found in the <a class="custom-link" href='https://unicef.sharepoint.com/teams/ECAR-HN/Repository/Forms/AllItems.aspx?viewid=76d87c9b%2D7e3b%2D4046%2Db6e0%2D9298a4a105a1&id=%2Fteams%2FECAR%2DHN%2FRepository%2FImmunization%2FReports%2FRoot%20causes%20analysis%20at%20subnational%20level' target="_blank">link</a>.</p>
            </div>

            <h1 class="title">Moldova (Republic of)</h1>
            <div className="col2">
    
                <p>In Moldova, nationwide DTP3 coverage is 91% and 11% of districts report coverage of 80% or less (2019)<sup class="pointer" data-class="custom-tooltip" data-place="top" data-tip={tooltip1} data-for="tooltip1">1</sup>.</p>
                <ReactTooltip id="tooltip1" place="top" html={true} clickable={true} />
                <p>To better understand the immunization system low performance, root cause analyses were conducted in three districts: Balti, Ceadir Lunga, and Dondusheni. Below is a summary of the main root causes and recommendations to strengthen the current immunization systems.</p>

                <h6 class="message-title">Balti, Ceadir Lunga and Dondusheni:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">Inability to track mobile population groups.</li>
                    <li class="list">Poor knowledge and capacity from family doctors’s resulting in uncertainties, false information, poor communication and follow up.</li>
                    <li class="list">Intense antivaccination campaigns on social media.</li>
                    <li class="list">Low readiness to vaccinate Roma children.</li>
                    <li class="list">Reluctance from other religious groups.</li>
                    <li class="list">Lack of awareness of immunization benefits among parents.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve performance of national immunization</strong></p>
                <ul>
                    <li class="list">Strengthen the defaulter tracking capacity and integrate it into the Primary Healthcare Information System (PHIS).</li>
                    <li class="list">Revise: 1) reporting forms for resident and registered population, and 2) vaccination cards to include new guidelines for contraindications.</li>
                    <li class="list">Capacity building amongst all immunization service providers on interpersonal communication, contraindications, tracking systems and revised reporting forms.</li>
                    <li class="list">Set-up a social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation to inform immunization interventions.</li>
                    <li class="list">Strengthen health workers capacities to identify and address vaccine hesitancy and strengthen confidence in immunization through in-service training and integration of relevant modules in health workers training curricula.</li>
                    <li class="list">Increase access of the population to reliable information on immunization through improving content on relevant web pages and pro-active continuous engagement on social media and other channels preferred by the population.</li>
                    <li class="list">Engage with opinion leaders and influencers to promote immunization as a norm.</li>
                </ul>
            </div>

            <div class="separator"></div>

            <h1 class="title">Montenegro</h1>
            <div className="col2">
    
                <p>The nationwide DTP3 coverage for Montenegro is 86% (2018); and 13% of districts reported DTP3 coverage of less than 80%<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip2} data-for="tooltip2">2</sup>.</p>
                <ReactTooltip id="tooltip2" html={true} clickable={true} />
                <p>To better understand the immunization system low performance, root cause analyses were conducted in three municipalities: Berane, Budva, and Cetinje. Below is a summary of the results.</p>

                <h6 class="message-title">Berane, Budva, and Cetinje:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">Poor knowledge of immunization service providers on contraindications and interpersonal communication.</li>
                    <li class="list">Unclear immunization targets, and weak accountability for providers to achieve targets.</li>
                    <li class="list">Demotivation of providers, coupled with poor immunization enforcement strategies.</li>
                    <li class="list">Participation of health professionals in widespread media antivaccination campaigns.</li>
                    <li class="list">Parents and caregivers are unaware about vaccine benefits.</li>
                    <li class="list">In Berane and Budva municipalities, an absence of demand generating interventions was reported. In Berane alone there was hesitancy from Roma groups in visiting primary health centres for vaccination.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve immunization coverage:</strong></p>
                <ul>
                    <li class="list">Capacity building amongst all immunization service providers, on: vaccine safety and contraindications, interpersonal communications, and support supervision tools.</li>
                    <li class="list">Revise policies to prioritize and enforce mandatory immunization.</li>
                    <li class="list">Enact subnational demand generation strategies (communication for development, social mobilization, health literacy education).</li>
                    <li class="list">Conduct outreach and awareness raising in Roma communities in Berane municipality.</li>
                    <li class="list">Roll-out trainings for health workers to address their bias and hesitancy and strengthen their interpersonal communication and community engagement capacities to better identify and address hesitancy. Include relevant modules in health workers training curricula.</li>
                    <li class="list">Set-up a social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation and inform immunization interventions.</li>
                    <li class="list">Engage health workers and other influencers in communicating about the importance and benefits of immunization in national campaigns and on social media platforms.</li>
                </ul>
            </div>

            <img className="w-full stories" src={montenegro} alt={`montenegro`} />
            <p class="image-credit">© UNICEF/UN0201055/Krepkih</p>

            <div class="separator"></div>

            <h1 class="title">Bosnia and Herzegovina</h1>
            <div className="col2">
    
                <p>In 2018, the nationwide DTP3 coverage for Bosnia and Herzegovina was 73%. 39% of districts reported DTP3 coverage of less than or equal to 80%<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip3} data-for="tooltip3">3</sup>.</p>
                <ReactTooltip id="tooltip3" html={true} clickable={true} />
                <p>To better understand the immunization system low performance, root cause analyses were conducted in three cantons and two municipalities: Sarajevo, West Herzegovina, and Doboj cantons, and the municipalities of Banja Luka and Bijeljina. Many overlaps in root causes were recorded amongst these areas; below is a summary.</p>

                <h6 class="message-title">Banja Luka, Bijeljina, Doboj, Sarajevo and West Herzegovina:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">Poor knowledge of pediatricians in vaccine safety, contraindications and communication.</li>
                    <li class="list">Poor governance from schools and law enforcement by accepting, not reporting, and failure to follow up on cases of under vaccinated children.</li>
                    <li class="list">The absence of incentives for service providers to improve immunization coverage.</li>
                    <li class="list">Widespread antivaccination campaigns, particularly on social media.</li>
                    <li class="list">No defaulter tracking systems, magnified by the COVID-19 pandemic.</li>
                    <li class="list">Parents are unaware of vaccination benefits.</li>
                    <li class="list">Poor cantonal plans to: 1) promote immunization program, and 2) develop human resources.</li>
                    <li class="list">In West Herzegovina alone, amongst the root causes of immunization coverage is the lack of full-time epidemiologists.</li>
                    <li class="list">In Doboj, Sarajevo, Banja Luka, and Bijeljina, pediatricians are multi-tasked and overworked. There is an absence of Roma-specific immunization delivery interventions and many service providers in Roma communities are ill-equipped with relevant and up to date knowledge.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve performance of the national immunization program:</strong></p>
                <ul>
                    <li class="list">Build and implement a defaulter tracking system, factoring in new COVID-19 procedures.</li>
                    <li class="list">Capacity building amongst all immunization service providers on vaccine safety and contraindications, interpersonal communications, support supervision tools, and defaulter tracking.</li>
                    <li class="list">Enact: an annual immunization plan; an human resources plan; a stakeholder workshop; and demand-generating interventions; all with components specific to Roma groups, and tailored to the individual cantons/municipalities.</li>
                    <li class="list">Continue trainings for health workers to address their bias and hesitancy and strengthen their interpersonal communication and community engagement capacities to better identify and address hesitancy. Include relevant modules in health workers training curricula.</li>
                    <li class="list">Set-up a social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation and inform immunization interventions.</li>
                    <li class="list">Engage health workers, bloggers and other influencers in communicating about the importance and benefits of immunization in national campaigns and on social media platforms.</li>
                    <li class="list">Engage with Roma community influencers to promote immunization in the Roma population. Promote positive examples of vaccination, including in the Roma population, to increase public perception of vaccination as a social norm.</li>
                </ul>
            </div>

            <div class="separator"></div>

            <h1 class="title">Romania</h1>
            <div className="col2">
    
                <p>In Romania, nationwide DTP3 coverage is 88% (2019), whilst 19% of districts report coverage of less than 80% (2018) <sup class="pointer" data-class="custom-tooltip" data-tip={tooltip4} data-for="tooltip4">4</sup>.</p>
                <ReactTooltip id="tooltip4" html={true} clickable={true} />
                <p>A subnational root cause analysis was conducted in two Romanian districts: Calarasi and Timisoara. Many similarities in factors affecting immunization coverage were found in the two districts. Below is a summary of the major challenges and recommendations on improving immunization coverage in Calarasi and Timisoara.</p>

                <h6 class="message-title">Calarasi and Timisoara:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">Lack of a defaulter tracking system and challenges in retrieving vaccination history.</li>
                    <li class="list">Population mobility.</li>
                    <li class="list">Poor overall knowledge of Family Doctors (family doctors) and Family Nurses (FN) on vaccination and defaulter tracking.</li>
                    <li class="list">Unclear vaccination requirements and low demand for vaccination.</li>
                    <li class="list">Poor awareness, and refusal from parents on vaccination, and its importance and benefits.</li>
                    <li class="list">In Calarasi district: poor capacity to track migrant groups, weak vaccination regulatory frameworks and access barriers to underserved groups were reported.</li>
                    <li class="list">Low prioritization of vaccination in Timisoara.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve performance of the national immunization program:</strong></p>
                <ul>
                    <li class="list">Develop individual vaccination targets, and update reporting requirements on coverage, for each familily doctors office.</li>
                    <li class="list">Establish a defaulter tracking system and procedure for recovering vaccination history.</li>
                    <li class="list">Mapping of underserved population groups.</li>
                    <li class="list">Revise and finalize the job description of nurses and mediators in outreach activities.</li>
                    <li class="list">Support trainings for health workers to address their bias and hesitancy and strengthen their interpersonal communication and community engagement capacities to better identify and address hesitancy. Include relevant modules in health workers training curricula.</li>
                    <li class="list">Set-up a social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation and inform immunization interventions.</li>
                    <li class="list">Engage with the Roma community influencers to promote immunization in the Roma population. Promote positive examples of vaccination, including in the Roma population, to increase public perception of vaccination as a social norm.</li>
                    <li class="list">Development and implementation of:
                        <ul>
                            <li class="ml-5">1) the communication and awareness raising plan to increase demand amongst caregivers.</li>
                            <li class="ml-5">2) a vaccination outreach plan that includes: education, awareness raising; sets performance targets and indicators; and assigns family doctors and family nurses to the groups of underserved population.</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <img className="w-full stories" src={romania} alt={`romania`} />
            <p class="image-credit">© UNICEF/Romania</p>

            <div class="separator"></div>

            <h1 class="title">North Macedonia</h1>
            <div className="col2">
    
                <p>The nationwide DTP3 coverage for North Macedonia was 93% (2018); and 100% of districts reported DTP3 coverage of at least 80%<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip5} data-for="tooltip5">5</sup>.</p>
                <ReactTooltip id="tooltip5" html={true} clickable={true} />
                <p>To better understand the immunization system low performance, root cause analyses were conducted in three municipalities: Karposh, Kumanovo and Tetovo. Below is a summary of the results.</p>

                <h6 class="message-title">Karposh, Kumanovo and Tetovo:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">No supportive supervision tools and poor knowledge of immunization service providers on vaccine safety, contraindications and interpersonal communication.</li>
                    <li class="list">Weak and non-existent defaulter tracking systems.</li>
                    <li class="list">Demotivation of providers, coupled with weak immunization enforcement strategies.</li>
                    <li class="list">Weak enforcement of new COVID-19 regulations to increase immunization uptake. Poor documentation and absence of interventions amongst minority groups (e.g Roma).</li>
                    <li class="list">Widespread antivaccination campaigns on social media.</li>
                    <li class="list">Parents and caregivers are unaware of vaccine benefits.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve immunization coverage:</strong></p>
                <ul>
                    <li class="list">Build and implement a defaulter tracking system, factoring in new COVID-19 procedures.</li>
                    <li class="list">Capacity building amongst all immunization service providers, on: vaccine safety and contraindications, interpersonal communications, support supervision tools, defaulter tracking, and new COVID-19 guidelines.</li>
                    <li class="list">Implement interventions tailored specifically for Roma and other minority groups to improve service delivery and generate demand.</li>
                    <li class="list">Support trainings for health workers to address their bias and hesitancy and strengthen their interpersonal communication and community engagement capacities to better identify and address hesitancy. Include relevant modules in health workers training curricula.</li>
                    <li class="list">Set-up a social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation and inform immunization interventions.</li>
                    <li class="list">Engage with the Roma community influencers to promote immunization in the Roma population. Promote positive examples of vaccination, including in the Roma population, to increase public perception of vaccination as a social norm.</li>
                    <li class="list">Revise policies to prioritize and improve staff accountability for the immunization program.</li>
                    <li class="list">Enact subnational demand generation strategies (communication for development, social mobilization, education).</li>
                </ul>
            </div>

            <div class="separator"></div>

            <h1 class="title">Ukraine</h1>
            <div className="col2">
    
                <p>The nationwide DTP3 coverage for Ukraine is 80% (2018); and 98% of districts reported DTP3 coverage of less than 80%<sup class="pointer" data-class="custom-tooltip" data-tip={tooltip6} data-for="tooltip6">6</sup>.</p>
                <ReactTooltip id="tooltip6" html={true} clickable={true} />
                <p>To better understand the immunization system low performance, root cause analyses were conducted in four Oblasts: Chernovtsy, Ivano-Frankivsk, Lviv and Odessa. Below is a summary of the results.</p>

                <h6 class="message-title">Chernovtsy, Ivano-Frankivsk, Lviv and Odessa:</h6>

                <p><strong class="text-bold">Main root causes of poor immunization coverage</strong></p>
                <ul>
                    <li class="list">Poor local level immunization legislations and outbreak response policies/strategies.</li>
                    <li class="list">Weaknesses in vaccine supply chain and costs – many vaccine-related costs are unknown and likely underfinanced and there is poor capacity for vaccine stock management at all levels.</li>
                    <li class="list">Poorly motivated and incentivized staff.</li>
                    <li class="list">Widespread misinformation on outbreak and MMR vaccines.</li>
                    <li class="list">Absence of vaccination certificates for school admission.</li>
                </ul>
                <p><strong class="text-bold">Recommendations to improve immunization coverage:</strong></p>
                <ul>
                    <li class="list">Update legal framework to empower Oblast Health Autorities.</li>
                    <li class="list">Introduce school entry certificates to improve vaccination completion and reduce false certificates.</li>
                    <li class="list">Improve data availability and accuracy for decision making.</li>
                    <li class="list">Train immunization service providers on calendar changes and dealing with vaccine refusals.</li>
                    <li class="list">Promote intrasectoral collaboration, especially with education system to increase demand for immunization.</li>
                    <li class="list">Incorporate health education in school curricula to improve youth knowledge on immunization benefits.</li>
                    <li class="list">Strengthen national social listening and rumours management mechanism, to track and understand conversations around immunization, concerns and misinformation and inform immunization interventions.</li>
                    <li class="list">Consider development of supportive supervision, to support health workers in addressing vaccine hesitancy and strengthen confidence in and demand for immunization.</li>
                    <li class="list">Continue engaging health workers, bloggers and other influencers in communicating about the importance and benefits of immunization in national campaigns and on social media platforms.</li>
                </ul>
            </div>

          </article>
        </div>
    )
}

export default LookingAtImmunization
