import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'

const NavItemLink = ({setShowNav, title, navActive, linkTo, hashValue}) => {

  const [open] = useState(navActive);

  return (
    <div 
      className="
        text-sm text-left text-white w-full
        pt-1 pb-2"
    >
      <Link
        to={{
          pathname: linkTo,
          hash: hashValue
        }}
        onClick={() => setShowNav(false)}
        className={`
          ani03s cursor-pointer uppercase px-3 pb-1 inline-block
          ${open || navActive ? 'text-bold text-black' : 'text-white'}
        `}>
          { title }
      </Link>
    
    </div>
  )
}

NavItemLink.propTypes = {
    title: PropTypes.string.isRequired,
}

export default NavItemLink
